const FIInformation = {
  FI_NAME: "BCU Financial",
  FI_APPLICATION_NAME: "Online Banking Marketplace",
  FI_EMAIL: "https://bcufinancial.com/contact-us/",
  FI_CONDITIONS: "https://bcufinancial.com/terms-of-use/",
  FI_PRIVACY: "https://bcufinancial.com/privacy-policy/",
  FI_SITE: "https://bcufinancial.com",
  FI_CONTACT_US: "https://bcufinancial.com",
  FI_OVERDRAFT_URL: "https://bcufinancial.com/contact-us/",
  FI_BENEFICIARY_URL: "https://bcufinancial.com/contact-us/",
  FI_SHARE_AMOUNT: "$100",
  // FI_BUSINESS_SHARE_AMOUNT: "$50",
  // FI_OVERDRAFT_DEFAULT_AMOUNT: "$100.00",
  // FI_BOOK_APPOINTMENT: "https://bcu.coconutcalendar.com/service?more-stuff-here",
};

export default FIInformation;
