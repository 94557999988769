import FIInformation from "Constants/FIInformation";

const STRINGS = {
  VERSION: "AM-JAN14-2021",
  DOBPLACEHOLDER: "YYYY-MM-DD",
  COMMON: {
    CHOICE: {
      YES: "Yes",
      NO: "No",
    },
    CONTINUEBTN: "Continue",
    SEARCHBTN: "Search",
    BTN_BACK_TO_DASHBOARD: "Back to Dashboard",
    FI_NAME: `${FIInformation.FI_NAME}`,
    USER_SESSION_EXPIRED_ERROR: "User session is expired.",
    ERROR_LIST: "ErrorList",
    API_KEYS: {
      LOGOUT: "logout",
      RESET_STORE: "resetStore",
      UPDATE_APPLICANT: "updateApplicant",
      GET_ACCOUNT: "getAccount",
      GET_APPLICANT_LIST: "getApplicantList",
      DELETE_APPLICATION: "deleteApplication",
      GET_APPLICATION_LIST: "getApplicationlist",
      GET_PRODUCT_TEMPLATE: "getProductTemplate",
      GET_PRODUCT_VAULT: "GET_PRODUCT_VAULT",
      UPDATE_PRODUCT_INFORMATION: "updateProductInformation",
      GET_PRODUCT_INFORMATION: "getProductInformation",
    },
  },
  NETWORK: {
    NETWORK_CONNECTIVITY_ISSUE:
      "It appears you are not connected to the Internet.",
    OFFLINE: "offline",
    ONLINE: "online",
    ERROR: "Network Error",
  },
  POPUPMODAL: {
    BACKBUTTON: "BACK",
    OKBUTTON: "OK",
    CONTINUE: "CONTINUE",
  },
  DROPDOWN_INITIAL_TEXT: "Select dropdown item",
  SHOW: "SHOW",
  HIDE: "HIDE",
  HEADER: {
    SIGNINTITLE: "OPEN YOUR ACCOUNTS",
    WELCOME: "Welcome",
    SIGNOUT: "Sign Out",
    GLOBALNAV: {
      TITLE: "Main Navigation",
      SIGNIN: "Sign In",
      REGISTER: "Register",
      SIGNOUT: "Sign Out",
      SETTINGS: "Settings",
      CHANGEPASSWORD: "Change Password",
      LEGALESE: "Terms & Policies",
      PRIVACY: "Privacy Policy",
      CONDITIONS: "Terms & Conditions",
      CONTACTUS: "Contact Us",
      CUSTOMERSERVICE: "Connect With Us",
      COMPANYSITE: `${FIInformation.FI_NAME} Website`,
      PARENT_MARKETPLACE: `${FIInformation.FI_APPLICATION_NAME}`,
      GO_TO_OTHER_MARKETPLACE: `Go to ${FIInformation.FI_APPLICATION_NAME} _BANKING_TYPE_`,
    },
    MEMBER_INFO: "Member Info",
    REGISTRATION: "Register",
    SIGNIN: "Sign In",
    FORGOTPASSWORD: "Forgot Password",
  },
  INFOBANNER: {
    SECURESESSIONTEXT: "Session secured with SSL and 256-bit encryption.",
  },
  NAVBAR: {
    NAVBARBUTTONTEXT: "BACK",
  },
  HOME_BREADCRUMB: "Home",
  FORGOT_PASSWORD_BREADCRUMB: "Forgot Password",
  EXISTING_MEMBER_BREADCRUMB: "Existing Member",
  RECOVER_PASSWORD_BREADCRUMB: "Recover Password",
  CONFIRM_ACCOUNT_CREATE_BREADCRUMB: "Confirm Account",
  MEMBER_CREATION_BREADCRUMB: "Member Info",
  EMPLOYMENT_AND_INCOME_BREDCRUM: "New Member Account",
  GETTING_STARTED_BREADCRUMB: "New Member Account",
  COMPLIANCE_BREADCRUMB: "Compliance",
  THIRD_PARTY_BREADCRUMB: "Third Party",
  ACCOUNT_INFORMATION_BREADCRUMB: "Account Information",
  FUND_ACCOUNT_BREADCRUMB: "Fund Account",
  BENEFICIARIES_BREADCRUMB: "Beneficiaries",
  OVERDRAFT_PROTECTION_BREADCRUMB: "Overdraft Protection",
  SIGNATURE_BREADCRUMB: "e-Signature",
  QUESTIONNAIRE_BREADCRUMB: "Help Me Choose",
  SIGNIN: {
    SIGN_IN_BREADCRUMB: "Sign In",
    VAULTSIGNINTITLE: "SIGN IN",
    FORM: {
      USERNAMELABEL: "Email",
      EMAILPLACEHOLDER: "Enter email",
      MOBILENOPLACEHOLDER: "Mobile no.",
      PASSWORDLABEL: "Password",
      PASSWORDPLACEHOLDER: "Enter password",
      FORGOTPASSWORDLINK: "Forgot password",
      REMEBERUSERCHECK: "Remember me",
      CONTINUEBTN: "Continue",
      SUBMITBUTTON: "Sign In",
      OR: "Or",
      SSO_MORE_INFO_TITLE: "Online Banking Credentials",
      SSO_MORE_INFO_DESCRIPTION:
        "Using your online banking credentials to access the mareketplace is easy. Use the link below and once you have signed in to your online banking you can click the 'marketplace' button. You will be signed in to your marketplace profile and all your information and activity will be available to you.",
      CENTRAL_SIGNIN_HEADER: "Already do online banking?",
      CENTRAL_SIGNIN: "Use your online banking credentials to sign in",
    },
    INFOCONTAINER: {
      REGISTERTITLE: "First time here?",
      REGISTERNOWINFO1:
        "You will need to register your marketplace profile before continuing with your application(s).",
      REGISTERNAVIGATEBTN: "Register Now",
    },
    API_KEYS: {
      SIGNIN: "signIn",
    },
  },
  SETPASSWORD: {
    TITLE: "PROFILE PASSWORD",
    FORM: {
      NEWPASSWORDLABEL: "New password",
      RETYPEPASSWORDLABEL: "Retype new password",
      PASSWORDCRITERIA: "Passwords must be:",
    },
    INFOBLOCK: {
      TITLE: "Your profile password is not your online banking password.",
      DESCRIPTION: `Your online applications' profile allows you to begin, save, leave, continue and complete financial applications or apply online for new financial products offered by ${FIInformation.FI_NAME}.`,
    },
    ERROR: {
      CONFIRM_PASSWORD_ERROR: "Passwords do not match",
    },
    API_KEYS: {
      GET_PASSWORD: "getPassword",
      SET_PASSWORD: "setPassword",
    },
  },
  VAULT: {
    TITLE: {
      personal: "PERSONAL BASKET",
      business: "BUSINESS BASKET",
    },
    VAULTTITLE: "PERSONAL BASKET",
    VAULTTITLE_BUSINESS: "BUSINESS BASKET",
    PRODUCTDETAILSLINK: "See Details",
    IN_PROGRESS: "In Progress",
    VAULT_PASSWORD_BREADCRUMB: "Profile Password",
    PROCEED: "Proceed",
    EMPTY_VAULT_TEXT: " “0” item(s) in your basket",
    PRODUCT_COUNT_TITLE: " $ item(s) in your basket",
    DELETE_TITLE: "Remove Product",
    DELETE_DESCRIPTION: "Are you sure you want to remove this product?",
    DELETE_CONFIRM: "Remove",
    DELETE_CANCEL: "Cancel",
    API_KEYS: {
      GET_VAULT: "getVault",
      VAULT: "vault",
    },
  },

  FOOTER: {
    COPYRIGHT: "© 2024 Mobetize™. All rights reserved.",
    FI_LEGAL_NAME: " ",
    MOBETIZE: "Powered by Mobetize™",
  },

  FISITE_REDIRECT: {
    TITLE: `Let's get started!`,
    DESCRIPTION1: `We see you'd like to apply for _PRODUCT_ today, excellent choice!`,
    DESCRIPTION2: `You will need a marketplace profile in order to be able to continue, save, and track your online aplications.`,
    DESCRIPTION3: `With a marketplace profile, you can begin, save, sign out and then come back later to continue or submit applications for any of your financial products.`,
    DESCRIPTION4: `If this is your first time to the ${FIInformation.FI_NAME} Marketplace, hit register to continue with your application.`,
    DESCRIPTION5: `If you already have a profile with the marketplace, then hit sign in to continue.`,
    REGISTERBTN: `Register and Continue`,
    SIGNINBTN: `Sign In and Continue`,
  },

  REGISTRATION: {
    REGISTER_BREADCRUMB: "Register",
    TITLE: "REGISTER",
    FORM: {
      EMAILLABEL: "Email",
      EMAILLABEL_CONFIRM: "Confirm Email",
      EMAILPLACEHOLDER: "Enter email",
      EMAILPLACEHOLDER_CONFIRM: "Re-enter email",
      MOBILENOLABEL: "Mobile No.",
      MOBILENOPLACEHOLDER: "Enter mobile no.",
      TERMSCHECK: "Remember me",
      REGISTRATIONBTN: "Register",
      TERMSLABEL1:
        "By continuing, you confirm that you have read and agree to the",
      TERMSLABEL2: " terms and conditions ",
      TERMSLABEL3: `and your relationship with ${FIInformation.FI_NAME}.`,
    },
    ERROR: {
      EMAIL_DO_NOT_MATCH: "Email addresses do not match",
    },
    INFOBLOCK: {
      SIGNINTOCONTINUE: "Been here before?",
      SIGNINTOCONTINUEBTN: "SIGN IN",
      SIGNINTOCONTINUEINFO1:
        "Sign in to continue with your applications or shop around for new financial products.",
    },
    SECURESESSIONTEXT: "Session secured with SSL and 256-bit encryption.",
    API_KEYS: {
      EXISTING_USER: "existingUser",
      REGISTER: "Register",
    },
  },

  VERIFICATIONCODE: {
    VERIFICATION_CODE_BREADCRUMB: "Verification Code",
    TITLE: "VERIFICATION CODE",
    FORM: {
      VERIFICATIONCODELABEL: "Verification Code",
      VERIFICATIONCODEPLACEHOLDER: "Enter verification code",
    },
    INFOBLOCK: {
      TITLE: "Verification Code",
      SENTCODE: "We’ve sent a verification code to your mobile device. ",
      ENTERCODE: "Enter the code to continue.",
      RECEIVEDCODE: "Didn't receive a code?",
      RESENDCODEBTN: "Resend Code",
    },
    API_KEYS: {
      VERIFICATION_CODE: "verificationCode",
    },
  },

  FORGOTPASSWORD: {
    TITLE: "Forgot password",
    VERIFICATIONCODEMESSAGE: "How would you like to reset your password?",
    EMAILLABEL: "Email",
    TEXTMESSAGE: "Text Message",
    SECURESESSIONTEXT: "Session secured with SSL and 256-bit encryption.",
    OTP_TO: {
      EMAIL: "email",
      MSISDN: "registered mobile device.",
    },
    MODAL: {
      SSO: "Use the link within the email to set your password.",
      SUCCESS_MAIL:
        "Use the link within the email to continue to reset your password.",
      SUCCESS_MESSAGE:
        "Use this code in the next step to continue to reset your password.",
    },
    API_KEYS: {
      FORGOT_PASSWORD: "forgotPassowrd",
      RECOVER_PASSWORD: "recoverPassword",
    },
  },

  EMPLOYMENT_AND_INCOME: {
    TITLE: "EMPLOYMENT & INCOME",
    SUBTITLE: "Please provide the following:",
    SUBTITLE_MEMBER: "Please confirm/update your employment details",
    ADDRESSFORM: {
      SEARCH_ADDRESS: "SEARCH ADDRESS",
      EDIT_ADDRESS: "EDIT ADDRESS",
      STREET: "Street",
      ADDRESSSECTION: "RESIDENTIAL ADDRESS",
      MAILINGSECTION: "MAILING ADDRESS",
      UNIT_LABEL: "Apt. / Unit No.",
      UNIT_TYPE: "Unit Type",
      STREET_TYPE: "Street Type",
      DIRECTION: "Street Direction",
      IF_APPLICABLE: "If applicable",
      STREET_NO_AND_NAME: "Street No. and Name",
      STREET_NO: "Street No.",
      STREET_NAME: "Street Name",
      ADDRESS0: "Employer Address",
      ADDRESS1: "Address Line 1",
      ADDRESS2: "Address Line 2",
      ADDRESS_PLACEHOLDER: "1234 Main St...",
      CITY: "City",
      PROVINCE: "Province / Territory",
      POSTALCODE: "Postal Code",
      ADDRESSCONFIRMLABEL: "whether address is same as mailing address",
      COUNTRY: "Country",
      COUNTRIES: {
        CA: { NAME: "Canada", CODE: "CA" },
        US: { NAME: "United States", CODE: "US" },
      },
    },
    FORM: {
      EMPLOYMENT_STATUS: "Employment Status",
      JOB_TITLE: "Job Title",
      OCCUPATION: "Occupation",
      OCCUPATION_DISCLAIMER: `Please be SPECIFIC. For example: Home Construction Labourer. Or, if retired, please provide your last occupation. For example: Retired medical office administrator`,
      POSITION: "Position",
      POSITION_DISCLAIMER: `Please be SPECIFIC. For example: Home Construction Labourer. Or, if retired, please provide your last occupation. For example: Retired medical office administrator`,
      EMPLOYER_WORK_PHONE_AREA: "Employer work phone area",
      EMPLOYER_WORK_PHONE: "Employer work phone",
      EMPLOYER_NAME: "Employer Name",
      EMPLOYER_CONTACT_NO: "Employer Contact No.",
      ANNUAL_GROSS_INCOME: "Annual Gross Income",
      MONTHLY_GROSS_INCOME: "Monthly Gross Income",
      SOURCE_OF_INCOME: "Source of Income",
      TAX_RESIDENT_CONFIRMATION: "Are you a tax resident of Canada? ",
      COUNTRY: "COUNTRY",
      COUNTRYFIELDNAME: "countryName",
      MONTHLY_GROSS_INCOME_FIELDNAME: "monthlyGrossIncome",
      COUNTRYNAME: "US",
      RESIDENT_OF_OTHER_COUNTRY_CONFIRMATION:
        "Are you a tax resident of any other country? ",
      RECIDENT_OF_COUNTRY_IN_SELECTION_CONFIRMATION: "Are you a citizen of ",
      ADD_ANOTHER_COUNTRY: "Add Another Country ",
      TAX_IDENTITY_NO: "Tax Identification Number (TIN)",
      SSN: "Social Security Number (SSN)",
      SIN: "Social Insurance Number (SIN)",
      TAX_RESIDENCY: "TAX RESIDENCY",
      SELECT_BEST_OPTION_THAT_DESCRIBES_YOU:
        "Select the option that best describes you",
      LIST_THE_COUNTRIES: "List the countries you are a tax resident of",
      PLACEHOLDER: {
        EMPLOYMENT_STATUS: "Select the status that best describes you",
        OCCUPATION: "Select your occupation",
        OCCUPATION_TEXT: "Enter your occupation",
        POSITION: "Select your position",
        POSITION_TEXT: "Enter your position",
        EMPLOYER_WORK_PHONE_AREA: "Work phone area",
        EMPLOYER_WORK_PHONE: "Work phone",
        JOB_TITLE: "Enter job title",
        EMPLOYER_NAME: "Enter employer name",
        EMPLOYER_CONTACT_NO: "Enter employer contact no.",
        ANNUAL_GROSS_INCOME: "Enter your yearly amount before taxes",
        SOURCE_OF_INCOME:
          "Select the option that best describes your main source of income",
        COUNTRY: "Select country",
        ADD_ANOTHER_COUNTRY: "Add Another Country ",
        SSN: "Enter social security number (SSN)",
        SIN: "Enter social insurance number (SIN)",
        LIST_THE_COUNTRIES: "List the countries you are a tax resident of",
        SINPLAACEHOLDER: "Social insurance number (SIN)",
      },
      ERROR: {
        TAX_RESIDENT_CONFIRMATION: "whether you are tax resident of canada",
        COUNTRY: "country name",
        TAX_RESIDENT_OF_OTHER_COUNTRY_CONFIRMATION:
          "whether you are tax resident of another country or not",
        RECIDENT_OF_COUNTRY_IN_SELECTION_CONFIRMATION:
          "whether you are a resident of another country or not",
        TAX_IDENTITY_NO: "tax identity number",
        SSN: "social security number (SSN)",
        SIN: "social insurance number (SIN)",
        LIST_OF_COUNTRIES: "countries you are a tax resident of",
        BOTHSELECTEDNONEERROR:
          "you must be the tax resident of at least one country",
        COUNTRY_LIST: "country list",
        INCOME_LIST: "incomesource list",
        EMPLOYMENT_STATUS: "employment status",
        JOB_TITLE: "job title",
        EMPLOYER_NAME: "employer name",
        EMPLOYER_CONTACT_NO: "employer contact number",
        MONTHLY_GROSS_INCOME: "monthly gross income",
        SOURCE_OF_INCOME: "source of income",
        ADDRESS1: "address line 1",
      },
      ERRORMESSAGE: {
        EMPLOYMENT_STATUS: "Unable to fetch employment status from server",
        COUNTRY_LIST: "Unable to fetch country list from server",
        INCOME_LIST: "Unable to fetch incomesource list from server",
      },
      FIELDNAME: {
        AREYOUTAXRESIDENTOFCANADA: "areYouTaxResidentOfCanada",
        AREYOUTAXRESIDENTOFOTHER: "areYouTaxResidentOfOtherCountry",
      },
    },
    CONTINUE: "Continue",
    API_KEYS: {
      COUNTRY_LIST: "countryList",
      INCOME_SOURCE_LIST: "IncomeSourceList",
      STATUS_LIST: "StatusList",
    },
  },

  EXISTING_MEMBER: {
    TITLE: "EXISTING MEMBERS",
    CHOICE_MESSAGE: `Do you have an account or are you already a member of ${FIInformation.FI_NAME}?`,
    INFOBLOCK: {
      TITLE: "Existing member verification",
      DESCRIPTION: `If you are already an existing or returning business member of ${FIInformation.FI_NAME}, and use online banking, we can verify you with a one-time online log in.`,
    },
  },
  // DEBIT CARD
  DEBIT_CARD: {
    TITLE: "DEBIT CARD",
    CHOICE_MESSAGE: `Would you like a debit card for this account?`,
    DEBIT_CARD_TXT:
      "Your new debit card will be mailed directly to you in 5-10 business days. For security purposes, a pre-generated PIN will be sent separately 3-5 days after the debit card. You can personalize your PIN at any of our branches, our ATMs or partner ATMs.",
    DEBIT_CARD_TXT1: `Reminder: To complete your account opening process, as a credit union owned by our members, you will need to purchase a membership share (${FIInformation.FI_SHARE_AMOUNT}). You can do this in branch, at one of our ATMs, or via e-transfer.`,
    API_KEYS: {
      GET_BUNDLE_PRODUCT_RELATION: "getBundleProductRelation",
      ADD_BUNDLE_PRODUCT: "addBundleProduct",
      DELTE_DEBIT_CARD: "deleteDebitCard",
    },
    PRODUCT_TYPE: "DEBITCARD",
    PRODUCT_TYPE_DOXIM: "debitCard",
  },

  SIGNATURE: {
    TITLE: "e-SIGNATURE",
    CHOICE_MESSAGE: `Please enter initials to accept contract.`,
    INSTRUCTION_PAGE1_TITLE: "Almost finished!",
    INSTRUCTION_PAGE1_SUBTITLE: "Last steps...",
    INSTRUCTION_PAGE1_DESCRIPTION:
      "In order to finalize your _PRODUCT_, we need your electronic signature.",
    INSTRUCTION_PAGE1_DESCRIPTION1:
      "Tap or click the button below and your electronic agreement will open in a new window.",
    INSTRUCTION_PAGE1_DESCRIPTION2:
      'Simply mark the document as instructed. When done, return to this window and hit the "Continue" button.',
    INSTRUCTION_PAGE1_DESCRIPTION3:
      "For your convenience, we will also send you an email containing a link to the same document.",
    INSTRUCTION_PAGE2_TITLE: "Electronic agreement requested.",
    INSTRUCTION_PAGE2_SUBTITLE: "Last steps...",
    INSTRUCTION_PAGE2_DESCRIPTION:
      "Your electronic agreement document should have opened in another browser window.",
    INSTRUCTION_PAGE2_DESCRIPTION1: `Sign the document as instructed. When done, return to this window and hit the "Continue" button.`,
    INSTRUCTION_PAGE2_DESCRIPTION2:
      "If your document did not open in another window, you may have pop-ups blocked in your browser settings.",
    INSTRUCTION_PAGE2_DESCRIPTION3: `For your convenience, you can also access the same document from the link in the email we sent you. Once completed, simply sign back into your marketplace profile and hit "Proceed" to complete your application with ${FIInformation.FI_NAME}.`,
    INSTRUCTION_PAGE3_TITLE: "Electronic agreement requested.",
    INSTRUCTION_PAGE3_SUBTITLE: "Wait a second...",
    INSTRUCTION_PAGE3_DESCRIPTION:
      "It looks like your electronic agreement has not yet been signed by all applicants.",
    INSTRUCTION_PAGE3_DESCRIPTION1:
      "Please check your email and complete your electronic agreement.",
    INSTRUCTION_PAGE3_DESCRIPTION2: `If there is still an issue, please contact a ${FIInformation.FI_NAME} customer service rep.`,
    CLICKTOBEGIN: "Begin e-Signature Docs",
    WAIT_MESSAGE:
      "Thanks for signing your documents. We are now finalizing your banking details so you can enjoy all the benefits of being a member.",
    ESIGN_SEND_MESSAGE:
      "We are so thrilled to be a valued part of your banking relationship.  We are now preparing the necessary contracts for you to sign.",
    ESIGN_END_MESSAGE: `To complete your ${FIInformation.FI_NAME} application, please return to ${FIInformation.FI_APPLICATION_NAME} on the current tab after you have finished with the e-sign documents.`,
    API_KEYS: {
      ESIGN_CREATE: "createEsignDoc",
      ESIGN_SEND: "sendEsignPackage",
      ESIGN_GET: "getEsign",
    },
    STATUS: {
      ACTIVATED: "Activated",
      COMPLETED: "Completed",
    },
    CONTRACT: "e-SIGNATURE",
    SIGN: "Signature",
  },

  // MEMBER CREATION COMPONENT STRINGS
  MEMBERCREATION: {
    TITLE: "BEFORE WE START...",
    FIRST_CARD_TEXT:
      "Be sure you and any co-applicants have reached the age of majority.",
    FIRST_CARD_MOREINFO: `The age of majority is 18 in six provinces: Alberta, Manitoba, Prince Edward Island, Quebec, Saskatchewan, and Ontario.<br></br> The age of majority is 19 in four provinces and the three territories: British Columbia, New Brunswick, Newfoundland, Northwest Territories, Nova Scotia, Nunavut, and Yukon.`,
    SECOND_CARD_TEXT:
      "You and any co-applicants have a valid Social Insurance Number (SIN).",
    SECOND_CARD_MOREINFO: `A valid SIN is necessary for any and all primary and/or joint account holders for the purposes of tax reporting.`,
    THIRD_CARD_TEXT: "You and any co-applicants have a valid Govt. issued ID.",
    THIRD_CARD_MOREINFO: `A valid Govt. issued ID is required by law and is necessary for assisting in the identity validation process of any new or prospective members.`,
    FOURTH_CARD_TEXT: `As a new member, ${FIInformation.FI_SHARE_AMOUNT} from your intitial deposit will go towards your member shares purchase.`,
    FOURTH_CARD_MOREINFO: `When you become a ${FIInformation.FI_NAME} member, you become part owner of ${FIInformation.FI_NAME} by purchasing ${FIInformation.FI_SHARE_AMOUNT} in member shares.\<br></br>\ Not only do you earn interest on these shares each year, but they give you the opportunity to vote on major decisions and help steer how the credit union is run.`,
    TERM_TEXT: `By continuing, you confirm that you have read and agree to the terms and conditions and your relationship with ${FIInformation.FI_NAME}.`,
    F1_TEXT: `Keep me informed about ${FIInformation.FI_NAME} news updates and special offers.`,
    F1_ALERT: `You also understand that some financial applications may require ${FIInformation.FI_NAME} to request a hard credit check. This may or may not impact your credit score. If a hard credit check will be required for an application, you will be alerted to it and asked to "Agree" before being asked to submit your application.`,
    TERM_TEXT_DETAIL: `You also understand that some financial applications may require ${FIInformation.FI_NAME} to request a hard credit check. This may or may not impact your credit score. If a hard credit check will be required for an application, you will be alerted to it and asked to "Agree" before being asked to submit your application.`,
    TERMS_ERROR_TEXT: "agree to the above statements, terms and/or conditions",
    CONTACT_DETAILS: "",
    GOVT_ID: "GOVT ID",
    SIN_TEXT: "SIN",
    MEMBERSHARE_TEXT: "Member Shares",
    AGREE_TERMS: " Please read and agree to the following:",
    AGE_TEXT: "18+",
    API_KEYS: {
      TERMS_JSON: "termsJson",
      TERMS_LIST: "termsList",
    },
  },
  // ABOUT YOU COMPONENT STRINGS
  ABOUTYOU: {
    SCREENTITLE: "ABOUT YOU",
    UPLOADANDCONTINUE: "Upload & Continue",
    RETAKE: "Retake",
    CLICKTOBEGIN: "Tap To Begin",
    COMMONPART: {
      CAPTUREMODE: "Auto",
    },
    MEMBER_MESSAGE:
      "Please update your address if changed. Note, if you have a non-Canadian address, please contact your branch to update your legal address.",
    PARTONE: {
      SUBTITILE: "PART I: Identity Verification",
      ABOUTYOUINFO:
        "To verify your identity, you will need to upload pictures of your govt. issued photo ID.",
      LABEL: "Select the type of document you wish to scan",
      IDLABEL: "ID",
      IDERROR: "your ID type",
      DEFAULTIDPLACEHOLDER: "Select your govt. issued ID",
    },
    PARTTWO: {
      SUBTITILE: "PART II: ID Scan",
      PROTIP: "Pro Tip",
      ABOUTYOUINFO:
        "For best results, scan your ID in a well lit area against a contrasting background.",
      ABOUTYOUINFO1: "See more tips.",
      ABOUTYOUINFO2: "Take a picture of the front of your ID.",
      POPUPTITLE: "ID Scan Tips",
      POPUPINFO:
        "Place your document within the frame until all 4 edges are aligned and the border turns green as it is shown in the animation above. The capturing usually happens automatically.",
      POPUPINFO1:
        "Use a contrasting coloured background to help differentiate the edges of your document from its background.",
      POPUPINFO2: "Use a well and evenly lit area.",
      POPUPINFO3: "Ensure all data is readable.",
      POPUPINFO4: "Avoid reflections or glare.",
      IDFRONTLABEL: "Front of ID card",
      DOCUMENTSETTING: {
        DOCUMENTSIDE: "Front",
        GLARETHRESHOLD: 15,
      },
    },
    PARTTHREE: {
      SUBTITILE: "PART II: ID Scan",
      ABOUTYOUINFO: "Now take a picture of the back of your ID.",
      POPUPTITLE: "Selfie Tips",
      POPUPINFO:
        "Align your full face within the frame as it is shown in the animation above. The capturing usually happens automatically.",
      POPUPINFO1: "Use a well and evenly lit area.",
      POPUPINFO2: "Avoid shadowing of your face.",
      POPUPINFO3: "Avoid reflections or glare.",
      POPUPINFO4: `If your selfie looks blurry in the preview, hit "RETAKE".`,
      IDBACKLABEL: "Back of ID card",
      DOCUMENTSETTING: {
        DOCUMENTSIDE: "Back",
        DOCUMENTTYPE: "License",
        CAPTUREMODE: "Manual",
        GLARETHRESHOLD: 2.5,
        COMPRESSIONTYPE: "JPEG",
        COMPRESSIONQUALITY: "50",
        CAPTUREATTEMPTS: 1,
        MANUALTIMEOUT: 8,
      },
    },
    PARTFOUR: {
      SUBTITILE: "IDENTITY PART III: Selfie",
      ABOUTYOUINFO: "Lastly, you will need to upload a selfie.",
      SELFIELABEL: "Selfie",
    },
    PARTFIVE: {
      TITLE: "ABOUT YOU - REVIEW",
      REVIEWFORM: {
        PERSONALSECTION: "PERSONAL INFORMATION",
        FIRSTNAMELABEL: "First Name",
        FIRSTNAMEREF: "firstName",
        MIDDLENAMELABEL: "Middle Name",
        LASTNAMELABEL: "Last Name",
        DOBLABEL: "Date of Birth",
        DOBPLACEHOLDER: "YYYY-MM-DD",
        SCANLABEL: "whether information scanned correctly",
        NAME_DISCLAIMER:
          "Enter your legal name as it appears on your government-issued identification.",
      },
      THANKYOUTITLE: "Thanks ",
      THANKYOUTEXT: "We are almost finished with setting up your ",
      PERSONALINFO_SPINNER:
        "Thanks, we are verifying your personal information so you can complete your banking application.",
      REVIEWREQ: "Please review the information below and update if needed.",
      CONFIRMQUESTION:
        "Did your personal information, like names, date of birth, and address, scan correctly?",
      CONFIRMMESSAGE: "Please update your information.",
      ADDRESSFORM: {
        SEARCH_ADDRESS: "SEARCH ADDRESS",
        EDIT_ADDRESS: "EDIT ADDRESS",
        STREET: "Street",
        ADDRESSSECTION: "RESIDENTIAL ADDRESS",
        MAILINGSECTION: "MAILING ADDRESS",
        UNIT_LABEL: "Apt. / Unit No.",
        UNIT_TYPE: "Unit Type",
        STREET_TYPE: "Street Type",
        DIRECTION: "Street Direction",
        IF_APPLICABLE: "If applicable",
        STREET_NO_AND_NAME: "Street No. and Name",
        STREET_NO: "Street No.",
        STREET_NAME: "Street Name",
        ADDRESS0: "Address",
        ADDRESS1: "Address1",
        ADDRESS2: "Address2",
        ADDRESS_PLACEHOLDER: "1234 Main St...",
        CITY: "City",
        PROVINCE: "Province / Territory",
        POSTALCODE: "Postal Code",
        ADDRESSCONFIRMLABEL: "whether address is same as mailing address",
        COUNTRY: "Country",
        COUNTRIES: {
          CA: { NAME: "Canada", CODE: "CA" },
          US: { NAME: "United States", CODE: "US" },
        },
      },
      ADDRESSCONFIRMQUESTION:
        "Is this address the same as your mailing address?",
      FIELDNAME: {
        MAILINGSAMEASCIVIC: "mailingSameAsCivic",
        SCANCONFIRMATION: "scanConfirmation",
      },
    },
    UPLOADERRORMESSAGE:
      "Image quality issue. Retake the picture and try uploading again.",
    ALTERNATETEXT:
      "If this problem persists, try signing into your profile on another device, like a tablet or your mobile phone and then continue your application.",
    CONTACTINFO:
      "You can also use chatbot or call us at: 1 - 888 - 555 - 5555 to find a branch and come in to complete your application in person.",
    AGE_RESTRICTION: {
      TITLE: "Age restriction",
      MESSAGE:
        "You are not qualified for the product based on age. Please remove the product.",
    },
    PROVINCE_RESTRICTION: {
      TITLE: "Province restriction",
      MESSAGE:
        "New members must be _PROVINCE_ residents and provide a valid _PROVINCE_ address to continue.",
    },
    PROSPECT: {
      TITLE: "Duplicate data found",
      MESSAGE: "Please contact the branch to set up your application.",
    },
    ADDRESS_LIMIT: {
      TITLE: "Address Limit Error",
      ADDRESS_MESSAGE:
        "The address and apartment number combined cannot be more than",
      ADDRESS_MESSAGE1:
        "characters in total. You will need to delete a few characters from your address in order to be able to continue.",
      MAILING_MESSAGE:
        "The mailing address and apartment number combined cannot be more than",
      MAILING_MESSAGE1:
        "characters in total. You will need to delete a few characters from your address in order to be able to continue.",
    },
    GENERIC_ERROR_MESSAGE:
      "We were unable to process your ID scan as it was submitted. You will need to retry scanning your document again.",
    MESSAGE_ERROR_ADDRESS:
      "We could not verify your legal address. Please contact your branch.",
    MESSAGE_ERROR_ID_EXPIRED: "Hmm, it looks like your ID may have expired.",
    API_KEYS: {
      CREATE_APPLICATION: "createApplication",
      GET_ID_TYPE: "getIdType",
      GET_PROVINCE_LIST: "getProvinceList",
      INSTNT_DOC: "instntDoc",
      SUBMIT_VERIFY: "submitVerify",
      GET_APPLICANT: "getApplicant",
      CREATE_INSTANCE_KEY: "createInstanceKey",
      ADDRESS_CORRECTION: "addressCorrection",
    },
  },

  // GETTING STARTED COMPONENT STRINGS
  GETTING_STARTED: {
    TITLE: "GETTING STARTED",
    RESIDENT_STATUS: "What is your Canadian residency status?",
    CONFIRM_JOINT_ACCOUNT: "Will this be a joint account?",
    SELECT_BRANCH: "Select a preferred home branch location",
    SELECT_REASON_OPENED: "Select reason account is opened",
    SELECT_CHOICE_TO_RECIVE_ACCOUNT_STATEMENT:
      "How would you like to receive your statements and correspondence?",
    SELECT_CREDIT_REQUIRED: "Will you need [a debit card] [credit cards]?",
    OVERDRAFT_PROTECTION_REQUIRED: "Will you need overdraft protection?",
    PAPER: "Paper",
    EMAIL: "Email",
    PROMO_CODE: "Promo Code",
    REFERRAL_CODE: "Referral Code",
    ERROR: {
      NAME_ERROR: "name",
      EMAIL_ERROR: "valid email",
      RESIDENT_STATUS: "canadian recidency status",
      CONFIRM_JOINT_ACCOUNT: "whether joint account or not",
      SELECT_BRANCH: "home branch location",
      SELECT_CREDIT_REQUIRED: "whether you need [a debit card] [credit cards]",
      SELECT_CHOICE_TO_RECIVE_ACCOUNT_STATEMENT_REQUIRED:
        "how would you like to receive your account statements [email] [paper]",
      SELECT_REASON_REQUIRED: "reason for opening an account",
      OVERDRAFT_PROTECTION_REQUIRED: "Whether you need overdraft protection",
    },
    APPEND_DIV_ID: "appendDivID",
    FORM: {
      NAMEPLACEHOLDER: "Enter name",
      EMAILPLACEHOLDER: "Enter email",
      PROMO_CODE:
        "If you signed up for a credit union promotion and received a code, please enter it here",
      REFERRAL_CODE: "If you received a referral code, please enter it here",
    },
    API_KEYS: {
      BRANCH_LIST: "branchList",
      RESIDENT_LIST: "residentList",
      GETTING_STARTED: "gettingStarted",
      GETTING_STARTED_VAULT: "getGettingStartedVault",
    },
  },

  // confirm page constant start
  CONFIRM_CREATION: {
    TITLE: "CONFIRM & SUBMIT",
    FIRST_MSG: "Thank you ",
    SECOND_MSG: ", for completing your application.",
    REVIEW_DETAILS_MSG:
      "Please review your details before confirming and submitting your request.",
    CONSENT_MSG:
      "You must accept the terms of consent to a credit check should we require one for the purpose of fulfilling this application.",
    EDIT_BTN_TEXT: "Edit",
    // getting started block
    JOINT_ACCOUNT_REQUIRED: "Joint Account: ",
    BRANCH: "Branch: ",
    REASON_OPENED: "Reason Account Is Opened:",
    ACCOUNT_STATEMENT_CHOICE: "Receive statements via:",
    OVER_DRAFTPROTECTION__REQUIRED: "Overdraft Protection: ",
    RESIDENCY_STATUS: "Residency Status: ",
    // employment and income block
    CURRENT_EMPLOYER: "Current Employer: ",
    EMPLOYER_PHONE: "Employer Phone: ",
    EMPLOYER_SALARY: "Salary: ",
    EMPLOYMENT_STATUS: "Employment Status: ",
    INCOME_TYPE: "Income Type: ",
    ANNUAL_GROSS_INCOME: "Gross Annual Income: ",
    MONTHLY_GROSS_INCOME: "Gross Monthly Income: ",
    SOURCE_OF_INCOME: "Source of Income: ",
    JOB_TITLE: "Job Title: ",
    OCCUPATION: "Occupation: ",
    POSITION: "Position: ",
    TAX_RESIDENT_OF_CANADA: "Tax Resident of Canada: ",
    TAX_RESIDENT_OF_USA: "Tax Resident of USA: ",
    TAX_RESIDENT_OTHER_COUNTRY: "Tax Resident of Another Country: ",
    IS_CITIZEN_OF_TAX_SELECT_COUNTRY: "Citizen of Tax Selected Country: ",
    TAX_SELECT_COUNTRY: "Country: ",
    TAX_SELECT_COUNTRY_LIST: "Countries: ",
    TAX_IDENTITY_NO: "TIN:",
    SSN_NUMBER: "SSN:",
    SIN_NUMBER: "SIN:",
    // compliance block
    ACCOUNT_PURPOSE: "Account Purpose: ",
    ISTHIRDPARTY: "Third Party Usage: ",
    THIRDPARTYNAME: "Name of Third Party:",
    THIRDPARTYREL: "Relationship:",
    JURISDICTIONTYPE: "Jurisdiction:",
    CLOSEPERSONJURISDICTIONTYPE: "Close Person Jurisdiction Type:",
    ISPEPORHIO: "HIO ? ",
    CLOSEPERSONPEPORHIO: "Known PEP or HIO: ",
    APPLICANTTYPE: "Applicant Type:",
    CLOSEPERSONDETAILS: "Name of PEP or HIO:",
    PERSON_NAME: "Name: ",
    PERSON: "Person: ",
    HIO: "HIO ",
    PEP_DOMESTIC: "PEP Domestic",
    HIO_STATUS: "HIO",
    PEP_FOREGIN: "PEP Foregin",
    POSITION_HELD: "Position Held: ",
    INDICATE_WHO: "Who:",
    SAME_ADDRESS_AS_APPLICANT: "Shared address w/ applicant:",
    // about you block
    ABOUT_YOU: "ABOUT YOU",
    ADDRESS1: "Home Address: ",
    ADDRESS2: "Apt. / Unit No.: ",
    UNIT_TYPE: "Unit Type:",
    STREET_TYPE: "Street Type:",
    STREET_NO: "Street Number:",
    STREET_NAME: "Street Name:",
    DIRECTION: "Street Direction:",
    ADDRESS_PLACEHOLDER: "1234 Main St...",
    CITY: "City: ",
    DOB: "Date of Birth: ",
    FIRST_NAME: "First Name: ",
    MIDDLE_NAME: "Middle Name: ",
    SURNAME: "Last Name: ",
    POSTAL_CODE: "Postal Code: ",
    STATE: "Province / Territory: ",
    SAMEASMAILING: "Is your mailing address the same as your home address? ",
    MAILING_ADDRESS: "MAILING ADDRESS ",
    MAILINGADDRESSLINE1: "Mailing Address: ",
    MAILINGADDRESSLINE2: "Apt. / Unit No.: ",
    MAILING_UNIT_TYPE: "Unit Type:",
    MAILING_STREET_TYPE: "Street Type:",
    MAILING_STREET_NUMBER: "Street Number:",
    MAILING_STREET_NAME: "Street Name:",
    MAILING_DIRECTION: "Street Direction:",
    MAILINGCITY: "City: ",
    MAILINGPROVINCE: "Province / Territory: ",
    MAILINGPOSTALCODE: "Postal Code: ",
    MAILINGCOUNTRY: "Country:",
    ISDOBSCAN: "Did your ID scan correctly: ",
    // disclimer block
    ACCOUNT_DISCLAIMER: "You must agree to the following:",
    ACCOUNT_DISCLAIMER_LABEL: "I agree.",
    ACCOUNT_DISCLAIMER_ERROR: "Please accept the terms.",
    BUREAU_CHECK: "Credit Bureau Check",
    ACCOUNT_TERMS_CHECK:
      "To successfully fulfill this application, we require an up-to-date credit check, (6 months or newer). If we do not already have one on file, we may need to request a new one which will not have an impact on your credit score.",
    ACCOUNT_TERMS_CHECK1:
      "We will only request a credit check should we not already have one on file.",
    ACCOUNT_TERMS_CHECK_KYC:
      "As an existing member, to successfully fulfill this application, we require an up-to-date credit check. If we do not already have one on file, we may need to request a new one which can have an impact on your credit score.",
    ACCOUNT_TERMS_CHECK1_KYC:
      "As an existing member, we will only request a credit check should we not already have one on file.<br></br> Please note: if you've updated your personal information then this and possibly subsequent applications may need to be reviewed and verified by our staff.",
    THIRDPARTY_ACCOUNT:
      "Will any of your accounts be used by or on behalf of a third party?",
    MEMBER_PEP_HIO_CHOICE: "Are you considered to be a PEP or HIO?",
    PEP_HIO_CHOICE: "Is anyone close to you considered to be a PEP or HIO?",
    OFFICE_HELD_LABEL: "Office held:",
    JURISDICTION_LABEL: "Jurisdiction:",
    SOURCE_OF_FUNDS_LABEL: "Source of Funds:",
    ACCOUNT_PURPOSE_LABEL: "What is the intended use of the account?",
    EXISTING_CONTRACT: "Existing Contract",
    INTENDED_USE_OTHER: "Intended Use Details",
    API_KEYS: {
      CONFIRM_ACCOUNT_CREATE: "confirmAccountCreate",
    },
  },

  // Online Banking

  ONLINE_BANKING: {
    TITLE: "ONLINE BANKING",
    ONLINE_BANKING_BREADCRUMB: "Online Banking",
    REGISTER_FOR_ONLINE_BANKING_LABEL:
      "In this step, please set a temporary password for your online banking. Upon your first online banking login experience, you will be prompted to create a new online banking password.",
    REGISTER_FOR_ONLINE_BANKING_LEGALESE: `I agree my member number and password will be recorded by ${FIInformation.FI_NAME} and will be used as my digital signature for the purpose of opening a membership.`,
    AUTO_GEN_TEMPORARY_PASSWORD_LABEL: "Temporary Online Banking Password",
    COPY_TO_CLIPBOARD: "Copy to clipboard",
    COPIED_TO_CLIPBOARD: "Copied to clipboard!",
    AUTO_GEN_TEMPORARY_PASSWORD_MSG1:
      "Before continuing, be sure to copy this temporary password and save it to your device for your first online banking session.",
    AUTO_GEN_TEMPORARY_PASSWORD_MSG2: `You agree your member number and password will be recorded by ${FIInformation.FI_NAME} and will be used as your digital signature for the purpose of opening a membership.`,
    PROVIDE_TEMPORARY_PASSWORD_LABEL: "Online banking password",
    RETYPEPASSWORDLABEL: "Retype online banking password",
    SKIP: "Skip for now",
    SKIP_DISCLAIMER:
      "IMPORTANT: Skipping the Online Banking setup will require you to come into or call one of our branch locations to setup your Online Banking. Please do not skip this step if you want to setup your Online Banking now.",
    ONLINE_BANKING_TXT:
      "Visit Online Banking to view your account activity, create alerts and personalize your banking experience.",
    ONLINE_BANKING_TXT1: `Reminder: To complete your account opening process, as a credit union owned by our members, you will need to purchase a membership share (${FIInformation.FI_SHARE_AMOUNT}). You can do this in branch, at one of our ATMs, or via e-transfer.`,
    COPY_DEBIT_CARD_INSTRUCTIONS:
      "Please make a note of your debit card number below. This will be used to access your online banking profile.",
    DEBIT_CARD_NUMBER_LABEL: "Debit Card Number:",
    MEMBER_NUMBER_LABEL: "Member Number:",
    ERROR: {
      PASSWORD_REQUIRED: "Please fill your online banking temporary password",
    },
    API_KEYS: {
      ENABLE_ONLINE_BANKING: "enableOnlineBanking",
    },
  },
  // confirm page constant end

  STEPS: {
    GETTING_STARTED: "LET'S BEGIN",
    ABOUT_YOU: "ABOUT YOU",
    EMPLOYMENT_AND_INCOME: "EMPLOYMENT",
    COMPLIANCE: "COMPLIANCE",
    ACCOUNT_INFORMATION: "ACCOUNT INFORMATION",
    CONFIRM_ACCOUNT_CREATE: "CONFIRM",
    BENEFICIARIES: "BENEFICIARIES",
    OVERDRAFT_PROTECTION: "OVERDRAFT PROTECTION",
    SPOUSAL_INFO: "RRSP SPOUSAL INFO",
    DEBIT_CARD: "DEBIT CARD",
    ONLINE_BANKING: "ONLINE BANKING",
    SIGNATURE: "CONTRACT",
    JOINT_APPLICANT: "JOINT APPLICANT",
    JOINT_APPLICANT_STATUS: "JOINT APPLICANT STATUS",
    LOAN_DETAILS: "LOAN DETAILS",
    PROPERTY_DETAILS: "PROPERTY OWNERSHIP",
    SECURITY_VEHICLE: "VEHICLE DETAILS",
    INCOME: "INCOME",
    EXPENSE: "EXPENSES",
    ASSET: "ASSETS",
    LIABILITIES: "LIABILITIES",
    DOCUMENT_UPLOAD: "DOCUMENT UPLOAD",
    FEES: "FEES",
    COST_OF_BORROWING: "COST OF BORROWING",
    LOAN_SUMMARY: "LOAN SUMMARY",
    LOAN_ADJUDICATION_RESULTS: "LOAN RESULT",
    BEFORE_WE_START: "BEFORE WE START",
    DISBURSEMENT: "DISBURSEMENT",
    REPAYMENT: "REPAYMENT",
    VERIFICATION_CODE: "VERIFICATION",
    TERMS_AND_CONDITIONS: "TERMS AND CONDITIONS",
    LOAN_PURPOSE: "GETTING STARTED",
    BUSINESS_EXIST: "BUSINESS EXIST",
    BUSINESS_CLIENT: "BUSINESS CLIENT",
    BUSINESS_TYPE: "TYPE OF BUSINESS",
    BUSINESS_ELIGIBILITY: "ONLINE APPLICATION ELIGIBILITY",
    BUSINESS_START: "GETTING STARTED",
    BUSINESS_LOOKUP: "BUSINESS LOOK-UP",
    BUSINESS_INFO_ADDRESS: "REGISTERED BUSINESS ADDRESS",
    BUSINESS_INFO_MAIL: "REGISTERED MAILING ADDRESS",
    BUSINESS_INFO: "LET'S BEGIN",
    BUSINESS_CONTACT: "BUSINESS CONTACT DETAILS",
    BUSINESS_DETAILS: "BUSINESS DETAILS",
    BUSINESS_OPERATIONS: "BUSINESS OPERATIONS",
    BUSINESS_TAX: "BUSINESS TAXATION",
    BUSINESS_COMPLIANCE: "BUSINESS COMPLIANCE",
    BUSINESS_OVERDRAFT: "BUSINESS OVERDRAFT",
    BUSINESS_CHEQUE: "BUSINESS CHEQUE",
    BUSINESS_DEBIT: "BUSINESS DEBIT",
    BUSINESS_ONLINE: "BUSINESS ONLINE BANKING",
    BUSINESS_EMPLOYMENT: "BUSINESS EMPLOYMENT",
    BUSINESS_REVIEW: "BUSINESS REVIEW",
    BUSINESS_INDIVIDUAL: "BUSINESS INDIVIDUAL REVIEW",
    BUSINESS_MEMBER: "BUSINESS MEMBER",
    BUSINESS_MEMBER_REVIEW: "BUSINESS MEMBER REVIEW",
    BUSINESS_ABOUT_YOU: "BUSINESS ABOUT YOU",
    BUSINESS_CONFIRM: "BASIC BUSINESS CHEQUING REVIEW",
    BUSINESS_UPLOAD: "BUSINESS UPLOAD",
    BUSINESS_DOC_REVIEW: "BUSINESS DOCUMENTS REVIEW",
    TAX_INFO: "TAX INFO",
    GIC_INFO: "GIC DETAILS",
    THIRD_PARTY: "THIRD PARTY",
  },
  THIRD_PARTY: {
    TITLE: "THIRD PARTY",
  },
  COMPLIANCE: {
    TITLE: "COMPLIANCE",
    SUBTITLE_MEMBER: "Please confirm/update your PEP/HIO details",
    HELP: {
      THIRD_PARTY:
        "A third party is an individual or entity, other than the account holder or those authorized to give instructions about the account, who directs what happens with the account. If you are acting on someone else's instructions then that individual is considered a third party.",
      THIRD_PARTY_KYC:
        "As an existing member, a third party is an individual or entity, other than the account holder or those authorized to give instructions about the account, who directs what happens with the account. If you are acting on someone else's instructions then that individual is considered a third party.",
      MEMBER_PEP_HIO: `A politically exposed person or a head of an international organization is one who is or has formerly been entrusted with a prominent public function, in Canada or internationally, or, as an individual, has held executive positions of internationally exposed corporations. Some examples might include, but not limited to, heads of states or elected officials at any level of government, senior officials for government or state owned companies or agencies, diplomatic, judiciary or military officials and or executives, like a CEO, to a business which has operations in more than one country. If you do or have ever held a prominent public position like those listed above, please answer "yes".`,
      MEMBER_PEP_HIO_KYC: `As an existing member, a politically exposed person or a head of an international organization is one who is or has formerly been entrusted with a prominent public function, in Canada or internationally, or, as an individual, has held executive positions of internationally exposed corporations. Some examples might include, but not limited to, heads of states or elected officials at any level of government, senior officials for government or state owned companies or agencies, diplomatic, judiciary or military officials and or executives, like a CEO, to a business which has operations in more than one country. If you do or have ever held a prominent public position like those listed above, please answer "yes".`,
      ANY_PEP_HIO: `A person related to or close to a PEP or HIO is an individual who has one of the following relationships:
      •	Spouse or common-law partner;
      •	Child;
      •	Mother or father;
      •	Mother-in-law or father-in-law (Mother or father of the PEP's spouse or common-law partner);
      •	Brother, sister, half-brother, half-sister (A child of the PEP's mother or father).`,
      ANY_PEP_HIO_KYC: `As an existing member, a person related to or close to a PEP or HIO is an individual who has one of the following relationships:
      •	Spouse or common-law partner;
      •	Child;
      •	Mother or father;
      •	Mother-in-law or father-in-law (Mother or father of the PEP's spouse or common-law partner);
      •	Brother, sister, half-brother, half-sister (A child of the PEP's mother or father).`,
    },
    FORM: {
      ACCOUNT_PURPOSE_LABEL: "Select membership purpose",
      ACCOUNT_PURPOSE_PLACEHOLDER: "Select the status that best describes you",
      ACCOUNT_PURPOSE_ERROR: "membership purpose",
      THIRDPARTY_TITLE: "THIRD PARTY",
      THIRDPARTY_ACCOUNT:
        "Will any of your accounts be used by or on behalf of a third party?",
      THIRDPARTY_ACCOUNT_ERROR: "third party account",
      THIRDPARTY_NAME: "Name",
      THIRDPARTY_NAME_PLACEHOLDER: "Enter third party name",
      THIRDPARTY_NAME_ERROR: "third party name",
      THIRDPARTY_RELATIONSHIP: "Relationship",
      THIRDPARTY_RELATIONSHIP_PLACEHOLDER: "Select relationship",
      THIRDPARTY_RELATIONSHIP_ERROR: "relationship",
      MEMBER_PEP_HIO_CHOICE: "Are you considered to be a PEP or HIO?",
      PEP_HIO_CHOICE: "Is anyone close to you considered to be a PEP or HIO?",
      PEP_HIO_CHOICE_ERROR: "PEP or HIO",
      PERSON_TITLE: "PEP AND/OR HIO",
      INDICATE_WHO_LABEL: "Who is considered a PEP or HIO",
      INDICATE_WHO_PLACEHOLDER:
        "select the relationship considered a PEP or HIO",
      INDICATE_WHO_ERROR: "considered a PEP or HIO",
      PERSON_NAME: "Name",
      PERSON_NAME_PLACEHOLDER: "Name",
      PERSON_NAME_ERROR: "name",
      PERSON_RELATIONSHIP: "Relationship",
      PERSON_RELATIONSHIP_PLACEHOLDER: "Relationship",
      PERSON_RELATIONSHIP_ERROR: "relationship",
      HOI_CHOICE: "Select all those that may apply",
      HIO_CHOICE_ERROR: "all those that may apply",
      OFFICE_HELD_LABEL: "Office Held",
      OFFICE_HELD_PLACEHOLDER: "Enter office held",
      OFFICE_HELD_ERROR: "office held",
      JURISDICTION_LABEL: "Jurisdiction",
      JURISDICTION_PLACEHOLDER: "Enter jurisdiction",
      JURISDICTION_ERROR: "jurisdiction",
      JURISDICTION_TYPE_LABEL: "Jurisdiction Type",
      JURISDICTION_TYPE_PLACEHOLDER: "Enter jurisdiction type",
      JURISDICTION_TYPE_ERROR: "jurisdiction type",
      SOURCE_OF_FUNDS_LABEL: "Source of Funds",
      SOURCE_OF_FUNDS_PLACEHOLDER: "Enter source of funds",
      SOURCE_OF_FUNDS_ERROR: "source of funds",
      ADD_ANOTHER_PERSON: "Add Another",
      FIELDNAME: {
        ISTHIRDPARTACCOUNT: "isThirdPartyAccount",
        AREYOUPEPORHIO: "areYouPEPorHIO",
        MEMBERPEPDOMESTIC: "memberPEPDomestic",
        MEMBERPEPFOREIGN: "memberPEPForeign",
        MEMBERHIO: "memberHIO",
        ISANYONECONSIDEREDPEPORHIO: "isAnyoneConsideredPEPHIO",
        PEPDOMESTIC: "PEPDomestic",
        PEPFOREIGN: "PEPForeign",
        HIO: "HIO",
      },
      ERROR: {
        NAME: "name",
        RELATIONSHIP: "relationship",
        MEMBER_TYPE: "type",
        OFFICE_HELD: "office held",
        JURISDICTION: "jurisdiction",
        SOURCE_OF_FUNDS: "source of funds",
      },
    },
    CHOICE: {
      PEP_DOMESTIC: "PEP-Domestic",
      PEP_FOREIGN: "PEP-Foreign",
      HOI: "HIO",
    },
    CONTINUE: "Continue",
    API_KEYS: {
      ACCOUNT_PURPOSE_LIST: "accountPurposeList",
      INDICATE_WHO_LIST: "indicateWhoList",
    },
  },

  FUND_ACCOUNT: {
    FUND_CARD: {
      FUND_ACCOUNT: "FUND ACCOUNT",
      ACCOUNT: "Account ",
      FUND_METHOD: "Funding method ",
      AMOUNT: "Amount ",
    },
    FUND_ACCOUNT_BREADCRUMB: "Activate Account",
    ACCOUNTOPEN_TEXT1: "Now, Let's fund your account.",
    ACCOUNTOPEN_TEXT2:
      "we encourage you to fund your account so that you may begin to use your account.",
    VAULT_TITLE: "YOUR BASKET",
    CHEQUING_ACCOUNT: "GO SIMPLE - CHEQUING ACCOUNT",
    VAULT_STATUS: "Status:",
    VAULT_INSTITUTION: "Institution",
    VAULT_TRANSIT: "Transit No:",
    VAULT_ACCOUNT_NO: "Full Account No:",
    FUND_BUTTON: "Fund Button",
    FUND_TITLE: "Fund Account",
    FUND_AMOUNT_TITLE: "FUNDING AMOUNT",
    FUND_DEPOSIT_AMOUNT: "Deposit Amount",
    FUND_AMOUNT: "Enter amount",
    FUND_AMOUNT_SYMBOL: "$00.00",
    FUND_TRANSFER: "Transfer Date -",

    METHOD: "FUND ACCOUNT METHOD",
    AMOUNT: "FUND ACCOUNT AMOUNT",

    PAYMENT_TYPE_CREDIT_CARD: "Credit Card",
    PAYMENT_TYPE_DIRECT_DEPOSIT: "Direct Deposit",
    PAYMENT_TYPE_INTERAC: "Interac Online",

    FUND_ERROR_MSG: "Please enter/select amount",
    OTHER: "Other",
    CARDS: "Cards",
    DIRECT_DEBIT: "DirectDebit",
    FUND_COUNTRY: "CA",
    CREDIT_CARD: "creditCard",
    EFT: "eft",
    INTERAC: "interac",
    FUND_AMOUNT_ERROR_MSG: "Enter amount between($%min% to $%max%)",
    FUND_AMOUNT_NUMERIC_ERROR_MSG: "Fund amount provided must be numeric",
    FIINFO_ERROR_MSG: "Unable to FI Info from server",
    PAYSAFE_STAGE_BEFORE_PAYMENT: "BeforePayment",
    PAYSAFE_STAGE_DURING_PAYMENT: "DuringPayment",
    PAYSAFE_STAGE_AFTER_PAYMENT: "AfterPayment",
    FUND_TRANSACTION_ID: "Transaction ID: ",
    API_KEYS: {
      FUND_PAYMENT_DETAILS: "fundPaymentDetails",
      FUND_PROCESSOR: "fundProcessor",
    },
  },
  FUND_METHOD: {
    FUND_TITLE: "FUNDING METHOD",
    FUND_SUB_TITLE: "Select how you'd like to fund your account",
    FUND_ERROR_MSG: "Please select funding method",
    SKIP: "Skip for now",
    MESSAGE: [
      {
        Id: 3,
        Text:
          "Payments made with Interac Online are usually reflected in your account in 1 hour.",
      },
    ],
  },
  INVERITE_IFRAME_MODAL: {
    TITLE: "Inverite",
    FOOTER: "Your bank account login provided by Inverite",
    API_KEYS: {
      CREATE: "create",
      FETCH: "fetch",
      CREATE_ACCOUNT_LIST: "create/accountlist",
      FETCH_ACCOUNT_LIST: "fetch/accountlist",
    },
  },
  MARKETPLACE: {
    TITILE: "Online Marketplace",
    HEADER: {
      CARD: {
        TITLE: "Need _BANKING_TYPE_ Banking?",
        TITLE_PERSONAL: "Returning to the marketplace?",
        BODY: {
          // TITLE:
          //   " ",
          TITLE:
            "Click or tap the button to go to your _BANKING_TYPE_ banking marketplace.",
          TITLE_PERSONAL:
            "Sign in to your profile to continue your applications or check their statuses.",
          SIGNIN: "Sign In",
          SWITCH: "Go To _BANKING_TYPE_ Banking",
        },
        FOOTER: {
          TITLE: "New here? ",
          REGISTER: "Register Now",
        },
      },
    },
    PAGE_BANNER: {
      SIGN_IN: "SIGN_IN",
      REGISTER: "REGISTER",
      DASHBOARD_WELCOME_TEXT_UNKNOWN: "Welcome ",
      DASHBOARD_WELCOME_TEXT_KNOWN: "Hi ",
    },
    ERROR: {
      LIMIT: {
        TITLE: "Vault limit reached.",
        MESSAGE:
          "Vault is already full. Please proceed to process your applications.",
      },
    },
    BODY: {
      SEE_PRODUCTS: "See products for:",
      FILTERS: "Filters",
      FILTER_BY: "Filter by:",
      PERSONAL_BANKING: "Personal Banking",
      BUSINESS_BANKING: "Business Banking",
      CATEGORY: "Category",
      ALL: "All",
      SEE_DETAILS: "See details",
      BOOK_APPOINTMENT: "Book Appointment",
      ADDED: "Added",
      ADD_TO_VAULT: "Add To Basket",
      ADDED_TO_VAULT: "Added to basket",
      REMOVE_FROM_VAULT: "Remove from Basket",
      CANCEL: "Cancel",
      BRANCH_DETAILS: "See branch for details",
      MULTIPLE_PRODUCT_ADD:
        "We notice that you have added the same product more than once. Please review your basket if this was unintentional.",
      PRODUCT_ADDED: "Product added to basket",
      SEE_BRANCH_DETAILS: "See branch for details",
      HELP_ME_CHOOSE: "Help me choose",
      HELP_ME_CHOOSE_MORE_INFO_TITLE: 'What is "Help me choose"?',
      HELP_ME_CHOOSE_MORE_INFO_DESCRIPTION:
        "You will be asked to answer a quick series of questions. Based on your answers we can show you which products are the most appropriate and best suited for your needs.<br></br> We provide this tool to you for your ease and comfort and understanding of our products and services as part of our ongoing commitment to serve your needs FIRST.",
    },
    API_KEYS: {
      PRODUCT_LIST: "productList",
      PRODUCT_VAULT: "product_vault",
      GET_PRODUCT_VAULT: "get_product_vault",
      GET_FI_INFO: "get_fi_info",
    },
  },
  VAULT_DASHBOARD: {
    TABS: [
      {
        NAME: "MY PROFILE",
        TAB_NAME: "myProfile",
      },
      {
        NAME: "MARKETPLACE",
        TAB_NAME: "marketPlace",
      },
    ],
    MY_PROFILE: "MY PROFILE",
    MARKETPALCE: "MARKETPLACE",
    SHOPPING_CART: "Basket",
    APPLICATION_COMPLETE: "Completed Applications",
    MY_UPLOADED_DOCUMENTS: "Uploaded Documents",
    STATUS: "STATUS",
    YOUR_PRODUCT_OFFER: "Your Product Offers",
    CONTINUE: "Continue",
    SAVING_ACC: "Savings acc",
    ADD_TO_VAULT: "Add To Basket",
    PROCEED: "Proceed",
    SEE_DETAILS: "See details",
    DELETE_TITLE: "Remove Product",
    DELETE_DESCRIPTION:
      "Are you sure you want to remove this product from your basket?",
    NO_APPLICATION_COMPLETE: "0 completed",
    EMPTY_VAULT_TEXT: "0 items",
    EMPTY_DOCUMENT_TEXT: "0 items",
    UPLOAD_BTN: "Upload New",
    COMPLETED: "Completed",
    IN_REVIEW: "In Review",
    REJECTED: "Rejected",
    IN_PROGRESS: "In Progress",
  },
  JOINT_APPLICANT: {
    JOINT_APPLICANT_BREADCRUMB: "Joint Applicants",
    TITLE: "JOINT APPLICANTS",
    FORM: {
      APPLICANT_NO: "CO-APPLICANT NO. ",
      CONFIRM_JOINT_APPLICANT: "Will this be a joint application?",
      EMAILLABEL: "Email",
      CONFIRM_EMAILLABEL: "Confirm Email",
      EMAILPLACEHOLDER: "Enter email",
      EMAILPLACEHOLDER_CONFIRM: "Re-enter email",
      MOBILENOLABEL: "Mobile No.",
      MOBILENOPLACEHOLDER: "Enter mobile no.",
      APPLICANT_TYPE: "Applicant Type",
      APPLICANT_TYPE_DEFAULT_SELECT:
        "Select the best description of your co-applicant",
      SIGNATORY: "Make this applicant a signatory",
      REMOVE_COAPPLICANT: "Remove Joint Applicant",
      RESEND_COAPPLICANT: "Resend Email Invite",
      ADD_BTN: "Add Another",
      JOINT_PRESENT_LABEL:
        "Are your joint applicant(s) physically present with you right now?",
      NOTE: "* You can add up to 3 co-applicants",
      OWNERSHIP: "Authorized signatories",
      SURVIVORSHIP: "Ownership structure",
      SURVIVORSHIP_MORE_INFO:
        "The right of survivorship is a right granted to joint property owners that ensures the surviving owner automatically inherits the property in the event of the joint owner's death.",
      SURVIVORSHIP_MORE_INFO_KYC:
        "As an existing member, the right of survivorship is a right granted to joint property owners that ensures the surviving owner automatically inherits the property in the event of the joint owner's death.",
      SURVIVORSHIP_DISCLAIMER: `Note: If you wish to open a joint account without Right of Survivorship in place, please contact us for more details.`,
      PRIVACY_DISCLAIMER_TITLE: "Important",
      PRIVACY_DISCLAIMER:
        "To add a co-applicant, it's important that you consent to sharing the personal information entered in this application with your co-applicant and you confirm that you have your co-applicant's consent to provide their personal information in this application. For more information you can visit our privacy agreement section.",
      PRIVACY_DISCLAIMER2: "Upon successful account applications, co-applicants will need to visit a branch in order to set up their online banking.",
      JOINT_DISCLAIMER_TITLE: "Important",
      JOINT_DISCLAIMER:
        "Be sure the joint applicants listed below are the correct people you are inviting to this application. If you need to remove or add applicants from the list below, please select 'create new' and enter your co-applicants details and continue.",
      JOINT_DISCLAIMER2:
        "Be sure the joint applicants contact information on file is correct.",
      JOINT_DISCLAIMER3:
        "If the applicants listed below are not who you intended to invite to this application, try selecting a different membership or create a new one.",
      SSO_JOINT_MESSAGE:
        "Due to security reasons, you can only process an out of session application.",
      PLACEHOLDER: {
        OWNERSHIP: "Select authorized signatories",
        SURVIVORSHIP: "Select ownership structure",
      },
    },
    ERROR: {
      INVALID_EMAIL: "Invalid email.",
      INVALID_PHONE: "Invalid phone.",
      EMAIL_ERROR: "email.",
      EMAIL_DUPLICATE: "Duplicate email address.",
      PHONE_DUPLICATE: "Duplicate phone number.",
      EMAIL_DO_NOT_MATCH: "Email addresses do not match",
      MOBILE_ERROR: "phone number.",
      MOBILE_DUPLICATE: "Duplicate mobile phone.",
      TYPE_REQUIRED: "applicant type.",
      OWNERSHIP_REQUIRED: "ownership.",
      SURVIVORSHIP_REQUIRED: "survivorship.",
      CONFIRM_JOINT_ACCOUNT: "whether joint account or not",
      SELECT_BRANCH: "home branch location",
      SELECT_CREDIT_REQUIRED: "whether you need [a debit card] [credit cards]",
      SELECT_CHOICE_TO_RECIVE_ACCOUNT_STATEMENT_REQUIRED:
        "how would you like to receive your account statements [email] [paper]",
      OVERDRAFT_PROTECTION_REQUIRED: "Whether you need overdraft protection",
      PARTIAL_MATCH_TITLE: "Recheck your co-applicant's contact details",
      PARTIAL_MATCH_BODY1: `Our records show that this co-applicant has a registered ${FIInformation.FI_APPLICATION_NAME} profile; however, the contact information you provided does not match our records.`,
      PARTIAL_MATCH_BODY2: `To ensure they recieve their invite to this application, be sure you are entering the same email and phone number that your co-applicant used when they registered their ${FIInformation.FI_APPLICATION_NAME} profile.`,
      SAME_USER_MATCH_TITLE: "Recheck your co-applicant's contact details",
      SAME_USER_MATCH_BODY1: `It appears you are trying to add yourself as a co-applicant. You cannot add yourself to your own application as a co-applicant.`,
      SAME_USER_MATCH_BODY2: `By default, you are already included in this application. Use this co-applicant section to add individual(s) who you want to be included in this application.`,
      REGISTERED_USERS_MIX_MATCH_TITLE:
        "Recheck your co-applicant's contact details",
      REGISTERED_USERS_MIX_MATCH_BODY1: `It appears you may have added either your own email address or phone number in place of your co-applicant's. Be sure both the email address and the phone number belong to your co-applicant.`,
      REGISTERED_USERS_MIX_MATCH_BODY2: `By default, you are already included in this application.`,
    },
  },
  JOINT_APPLICANT_STATUS: {
    JOINT_APPLICANT_STATUS_BREADCRUMB: "Joint Applicant Status",
    TITLE: "Joint Application Status",
    SUBTITLE1:
      "Thank you for completing your portion of this joint application.",
    SUBTITLE_PENDING:
      "Your application status is PENDING until all participants have completed their portion of this application.",
    SUBTITLE_PENDING1:
      "For now, you can navigate back to your dashboard or sign out if you are finished.",
    SUBTITLE_PROCEED:
      "Your application looks ready for you to complete your online agreement and signatures.",
    TABLE_DESCRIPTION:
      "Below is a list of your applicants and their application statuses.",
    REMOVE_COAPPLICANT: "Remove Joint Applicant",
    EMAIL_LABEL: "Email:",
    MOBILENO_LABEL: "Mobile No.:",
    APPLICANT_TYPE_LABEL: "Applicant Type:",
    APPLICANT_TYPE_STATUS: "Status:",
    APPLICANT_INFO_TEXT1:
      "You will be notified by email when all applicants have completed their portion of the application and it is ready for you to complete your online agreement and signatures.",
    APPLICANT_INFO_TEXT2:
      "You can also check the progress of your co-applicants by opening the 'See Details' section of this application in your home dashboard.",
    APPLICANT_PROCEED_TEXT1: "Everything looks good to go!",
    APPLICANT_PROCEED_TEXT2: `Hit 'Continue' to proceed with your online agreement and signatures.`,
    DONE_FOR_NOW: "Done for now",
  },

  // OVERDRAFT PROTECTION COMPONENT STRINGS
  OVERDRAFT_PROTECTION: {
    TITLE: "OVERDRAFT PROTECTION",
    OVERDRAFT_PROTECTION_REQUIRED: "Will you need overdraft protection?",
    OVERDRAFT_PROTECTION_HINT_TEXT: `You can help minimize the risk of automatic payments bouncing with a ${FIInformation.FI_OVERDRAFT_DEFAULT_AMOUNT} overdraft limit on your chequing account.`,
    OVERDRAFT_PROTECTION_HINT_TEXT1: `For the current overdraft rate, go to <a href="${FIInformation.FI_OVERDRAFT_URL}" target="_blank">${FIInformation.FI_OVERDRAFT_URL}</a>.`,
    AGREE_TERMS: "Please read and agree to the following:",
    ERROR: {
      OVERDRAFT_PROTECTION_REQUIRED: "Whether you need overdraft protection",
      TERMS_AND_CONDITIONS: "Please agree to the terms and conditions",
    },
  },

  ACCOUNT_INFORMATION: {
    TITLE: "ACCOUNT INFORMATION",
    FORM: {
      ACCOUNT_PURPOSE_LABEL: "What is the intended use of the account?",
      ACCOUNT_PURPOSE_PLACEHOLDER: "Select the intended use of the account",
      PICK_CONTRACT:
        "Pick the existing Contract You'd Like to Link this product to",
      PICK_CONTRACT_PLACEHOLDER: "Existing Contract",
    },
    ERROR: {
      SELECT_INTENDED_USE: " intended use of the account",
      INTENDED_USE_ERROR: "Unable to fetch intended use list from server",
      INTENDED_USE_OTHER: "Please provide details of intended use",
      CONTRACT_ERROR: "Please select contract",
    },
    API_KEYS: {
      INTENDED_USE: "intendedUse",
      BANKING_PRODUCT_LIST: "bankingProductList",
    },
  },

  SPOUSAL_INFO: {
    SCREENTITLE: "RRSP Spousal Information",
    SPOUSE_QUI: "Is this RRSP for your Spouse?",
    PRIVACY_DISCLAIMER_TITLE: "Important",
    PRIVACY_DISCLAIMER:
      "To add a spouse, it's important that you consent to sharing the personal information entered in this application with your spouse and you confirm that you have your spouse's consent to provide their personal information in this application. For more information you can visit our privacy agreement section.",
    API_KEYS: {
      SPOUSAL_DATA: "spousalData",
    },
  },

  DEBIT_CARD_BREADCRUMB: "Debit Card",
  BENEFICIARIES: {
    TITLE: "BENEFICIARIES",
    PRIVACY_DISCLAIMER_TITLE: "Important",
    PRIVACY_DISCLAIMER:
      "To add a beneficiary, it's important that you consent to sharing the personal information entered in this application with your beneficiary and you confirm that you have your beneficiary's consent to provide their personal information in this application. For more information you can visit our privacy agreement section.",
    FORM: {
      RESIDENCE_ADDRESS: "Residential Address",
      BENEFICIARY: "BENEFICIARY #",
      BENEFICIARIES_PURPOSE_LABEL:
        "Do you wish to register beneficiaries for this account?",
      ADD_ANOTHER: "Add Another",
      PERSONAL_INFORMATION: "Personal Information",
      NAME_DISCLAIMER:
        "Enter legal names as they appear on government-issued identification.",
      FIRSTNAMELABEL: "First Name",
      MIDDLENAMELABEL: "Middle Name",
      LASTNAMELABEL: "Last Name",
      DOBLABEL: "Date of Birth",
      DOBPLACEHOLDER: "YYYY-MM-DD",
      RELATIONSHIP: "Relationship",
      RELATIONSHIP_PLACEHOLDER: "Select relationship",
      BENEFICIARY_TYPE: "Beneficiary Type",
      BENEFICIARY_TYPE_INFO: `You can learn more about beneficiary types at <a href='${FIInformation.FI_BENEFICIARY_URL}' target='_blank'>${FIInformation.FI_BENEFICIARY_URL}</a>`,
      BENEFICIARY_TYPE_INFO_KYC: `As an existing member, you can learn more about beneficiary types at <a href='${FIInformation.FI_BENEFICIARY_URL}' target='_blank'>${FIInformation.FI_BENEFICIARY_URL}</a>`,
      PERCENTAGE: "Percentage",
      BENEFICIARY_PLACEHOLDER: "Select beneficiary",
      ADDRESSSECTION: "Residential Address",
      ADDRESS0: "Address",
      ADDRESS1: "Address1",
      ADDRESS2: "Address2",
      ADDRESS_PLACEHOLDER: "1234 Main St...",
      CITY: "City",
      PROVINCE: "Province / Territory",
      POSTALCODE: "Postal Code",
      MOBILENOLABEL: "Mobile No.",
      MOBILENOPLACEHOLDER: "Enter mobile no.",
      SIN: "Social Insurance Number (SIN)",
      TAX_RESIDENCY: "TAX RESIDENCY",
      COUNTRY: "country",
      SELECT_COUNTRY: "Select country",
      SIN_PLACEHOLDER: "Enter social insurance number (SIN)",
      CANCEL: "CANCEL",
      SAVE: "SAVE",
      CLEAR: "CLEAR",
      DELETE_TITLE: "Remove Beneficiaries",
      DELETE_DESCRIPTION: "Are sure you want to remove these beneficiaries?",
      DELETE_SINGLE_DESCRIPTION:
        "Are sure you want to remove this beneficiary?",
      ADD_BENEFICIARY_TITLE: "Add Beneficiary",
      ADD_BENEFICIARY_DESCRIPTION: "Beneficiary added successfully.",
      UPDATE_BENEFICIARY_TITLE: "Edit Beneficiary",
      UPDATE_BENEFICIARY_DESCRIPTION: "Beneficiary updated Successfully.",
      DELETE_BENEFICIARY_TITLE: "Remove Beneficiary",
      DELETE_BENEFICIARY_DESCRIPTION: "Beneficiary deleted Successfully.",
      FIRSTNAMELABELCONFIRMPAGE: "First Name:",
      MIDDLENAMELABELCONFIRMPAGE: "Middle Name:",
      LASTNAMELABELCONFIRMPAGE: "Last Name:",
      DOBLABELCONFIRMPAGE: "Date of Birth:",
      RELATIONSHIPCONFIRMPAGE: "Your beneficiary's relationship to you:",
      BENEFICIARY_TYPECONFIRMPAGE: "Beneficiary Type:",
      ADDRESS1CONFIRMPAGE: "Street address:",
      ADDRESS2CONFIRMPAGE: "Apt. / Unit No.: ",
      CITYCONFIRMPAGE: "City:",
      PROVINCECONFIRMPAGE: "Province / Territory:",
      POSTALCODECONFIRMPAGE: "Postal Code:",
      MOBILENOLABELCONFIRMPAGE: "Your beneficiary's mobile no.:",
      COUNTRYCONFIRMPAGE: "Select country:",
      SINCONFIRMPAGE: "Social Insurance Number (SIN):",
    },
    ERROR: {
      BENEFICIARY_ERROR: " beneficiary choice",
      RELATIONSHIP_ERROR: "relationship",
      PRIMARY_REQUIRED: "One primary beneficiary is required.",
      ONLY_ONE_PRIMARY_REQUIRED: "Only one beneficiary can be a primary.",
      NOT_HUNDRED_PERCENT:
        "Total percentage for all beneficiaries should be 100",
      NOT_HUNDRED_PERCENT_PRIMARY:
        "Total percentage for all primary beneficiaries should be 100",
      NOT_HUNDRED_PERCENT_ALTERNATE:
        "Total percentage for all alternate beneficiaries should be 100",
    },
    API_KEYS: {
      GET_BENEFICIARY_TYPE: "getBeneficiaryType",
      GET_BENEFICIARY_RELATION: "getBeneficiaryRelation",
      ADD_BENEFICIARIES: "addBeneficiaries",
      GET_BENEFICIARIES: "getBeneficiaries",
      DELETE_BENEFICIARY: "deleteBeneficiary",
      EDIT_BENEFICIARIES: "editBeneficiary",
    },
  },
  JOIN_APPLICANT: {
    INVITE_APPLY_URI: "/inviteapply",
    VAULT_KEY: "JOINT-APPLICANT",
    JOINT_STATUS_PENDING: "PENDING",
    JOINT_STATUS_FINISHED: "FINISHED",
    JOINT_STATUS_DELETED: "DELETED",
    IN_SESSION: "IN-SESSION",
    OUT_OF_SESSION: "OUT_OF_SESSION",
    JOINT_APPLICANT_SLOTID: "JOINT_APPLICANT_SLOTID",
    JOINT_APPLICANT_APPLICATION_ID: "JOINT_APPLICANT_APPLICATION_ID",
    API_KEYS: {
      ADD_APPLICATION_SLOT: "addApplicationSlot",
      ADD_JOINT_APPLICANTS: "addJointApplicants",
      INVITE_APPLICATION_SLOT: "inviteApplicationSlot",
      GET_APPLICANT_TYPE: "getApplicantType",
      GET_APPLICATION_PROPERTY: "getApplicationProperty",
      DELETE_APPLICATION_SLOT: "deleteApplicationSlot",
      ADD_GLOBAL_VAULT: "addGlobalVault",
      GET_GLOBAL_VAULT: "getGlobalVault",
      APPLICANT_CREATE_REQUEST: "applicantCreateRequest",
      APPLICANT_CREATE_SELF: "applicantCreateSelf",
      OTP_VERIFY_INVITE: "otpVerifyInvite",
      FILL_APPLICATION_SLOT: "fillApplicationSlot",
      CREATE_REQUEST_KEY_SELF: "request",
      CREATE_REQUEST_SELF: "self",
      APPLICANT_CREATE_CONFIRM: "confirmApplicantCreate",
    },
    PRODUCT_TEMPLATE: [
      {
        templateId: 1,
        flow: [
          {
            index: 0,
            pageId: "Page1",
            componentName: "GETTING_STARTED",
            componentType: "Applicant",
          },
          {
            index: 1,
            pageId: "Page2",
            componentName: "DOC_VERIFICATION",
            componentType: "Applicant",
          },
          {
            index: 2,
            pageId: "Page3",
            componentName: "ABOUT_YOU",
            componentType: "Applicant",
          },
          {
            index: 3,
            pageId: "Page4",
            componentName: "EMPLOYMENT_AND_INCOME",
            componentType: "Applicant",
          },
          {
            index: 4,
            pageId: "Page5",
            componentName: "COMPLIANCE",
            componentType: "Applicant",
          },
          {
            index: 5,
            pageId: "Page6",
            componentName: "CONFIRM_ACCOUNT_CREATE",
            componentType: "Application",
          },
        ],
      },
    ],
    PRODUCT_TEMPLATE_IN_SESSION: [
      {
        templateId: 1,
        flow: [
          {
            index: 0,
            pageId: "Page1",
            componentName: "GETTING_STARTED",
            componentType: "Applicant",
          },
          {
            index: 1,
            pageId: "Page2",
            componentName: "DOC_VERIFICATION",
            componentType: "Applicant",
          },
          {
            index: 2,
            pageId: "Page3",
            componentName: "ABOUT_YOU",
          },
          {
            index: 3,
            pageId: "Page15",
            componentName: "CONFIRM_ACCOUNT_CREATE",
            componentType: "Application",
          },
        ],
      },
    ],
  },
  LOAN_DETAILS: {
    TITLE: "Loan Details",
    SUBTITLE: "Enter your loan details here to begin.",
    BREADCRUMB: "Loan Details",
    LOAN_SUMMARY: "Loan Summary",
    COST_TO_BORROW: "Cost To Borrow",
    // LOAN DETAILS COMPONENT STRINGS
    FORM: {
      LOC: {
        LABEL_AMOUNT: "Line of credit amount",
        LABEL_ESTIMATED_RATE: "* Estimated interest rate for this loan",
        TABLE_TITLE: "Here's how your line of credit is looking so far...",
        HEADER: "Line of Credit Breakdown",
        MAXIMUM_CREDIT_AMOUNT: "Maximum credit limit:",
        REQUESTED_CREDIT_AMOUNT: "Requested credit limit:",
        CURRENT_INTEREST_RATE: "Current interest rate:",
        EXPECTED_PAYMENT_SCHEDULE: "Expected repayment schedule:",
        LOAN_TERM: "Loan term:",
        LOAN_TERM_REVOLVING: "Revolving",
        LABEL_MONTHLY_PAYMENT: "Monthly payment:",
        MONTHLY_PAYMENT: "Based on current rate and amount owed.",
        NOTE1:
          "In the next step you will be required to submit information about your property.",
        FINE_PRINT1:
          "* Interest rate is variable and will be prone to fluctuations over time. Initial interest rate will be determined at the time loan adjudication.",
        FINE_PRINT2:
          "** Monhtly payment amount is for display purposes only. It is based on a monthly payment frequency with the assumption the full amount of the line of credit is being used. Monthly payment is based on interest only.",
      },
      HELOC: {
        LABEL_ASSESSED_VALUE: "Your assessed property value",
        ASSESSED_AMOUNT_PLACEHOLDER: "$",
        LABEL_MORTGAGE_BALANCE: "Estimated outstanding mortgage balance",
        LABEL_HELOC_REQUEST_AMOUNT: "HELOC amount request",
        LABEL_HELOC_REQUEST_AMOUNT_DESCRIPTION:
          "Based on your property value and mortgage balance you may pre-qualify for a line of credit up to",
        LABEL_TERM: "HELOC term",
        TABLE_TITLE: "Here's how your line of credit is looking so far...",
        HEADER: "Line of Credit Breakdown",
        MAXIMUM_CREDIT_AMOUNT: "Maximum credit limit:",
        REQUESTED_CREDIT_AMOUNT: "Requested credit limit:",
        MAX_AMOUNT_HINT: "You may be able to pre-qualify for up to",
        CURRENT_INTEREST_RATE: "Current interest rate:",
        EXPECTED_PAYMENT_SCHEDULE: "Expected repayment schedule:",
        MAXIMUM_AMOUNT: "Maximum amount:",
        LOAN_TERM: "Loan term:",
        LOAN_TERM_REVOLVING: "Revolving",
        LABEL_MONTHLY_PAYMENT: "Monthly payment:",
        MONTHLY_PAYMENT: "Based on interest",
        NOTE1:
          "In the next step you will be required to submit information about your property.",
        FINE_PRINT1:
          "* Interest rate is variable and will be prone to fluctuations over time. Initial interest rate will be determined at the time loan adjudication.",
        FINE_PRINT2:
          "** Monhtly payment amount is for display purposes only. It is based on a monthly payment frequency with the assumption the full amount of the line of credit is being used. Monthly payment is based on interest only.",
        ERROR: {
          ASSESSED_VALUE_REQUIRED: "assessed property value.",
          MORTGAGE_BALANCE_REQUIRED: "mortgage balance.",
          HELOC_AMOUNT_REQUIRED: "heloc loan amount.",
        },
      },
      MORTGAGE: {
        ERROR: {
          VALUE_REQUIRED: "property value.",
        },
      },
      AUTO: {
        LABEL_VALUE: "What is the price of the vehicle you are purchasing?",
        LABEL_DOWN_PAYMENT:
          "If you have a down payment, enter the amount or percentage below.",
        LABEL_DOWN_PAYMENT_AMOUNT: "Amount",
        LABEL_DOWN_PAYMENT_PERCENTAGE: "Percentage",
        LABEL_ESTIMATED_LOAN: "* Estimated loan amount",
        LABEL_TERM: "Loan term",
        LABEL_ESTIMATED_RATE: "* Estimated interest rate for this loan",
        TABLE_TITLE: "Here's how your vehicle loan is looking so far...",
        NOTE1:
          "In the next step you will be required to submit information about the vehicle.",
        NOTE2:
          "*Required information will include make, model, Vehicle Identification Number (VIN).",
        FINE_PRINT1:
          "* Final interest rates are determined at the time of loan approval.",
        FINE_PRINT2:
          "** You will be able to set the payment frequency based on your needs once the loan has been approved.",
        ERROR: {
          VEHICLE_PRICE_REQUIRED: "vehicle price value.",
        },
      },
      PRODUCT_LABEL_LOAN_AMOUNT: "Loan amount",
      PRODUCT_LABEL_LOAN_TERM: "Loan term (months)",
      PRODUCT_LABEL_LOAN_TERM_YEARS: "Loan term (years)",
      MONTH: "mo.",
      MONTHS: "month(s)",
      YEAR: "year",
      YEARS: "years",
      PRODUCT_LABEL_ESTIMATED_RATE: "* Estimated interest rate for this loan",
      PRODUCT_LABEL_REPAYMENT_SCHEDULE: "Payment frequency",
      PRODUCT_LABEL_REPAYMENT_SCHEDULE_DATE: "First payment date",
      PRODUCT_LABEL_REPAYMENT_SCHEDULE_DATE_HINT:
        "Enter the date for your first payment.",
      PRODUCT_LABEL_LOAN_DETAILS: "Your loan details: ",
      PRODUCT_LABEL_LOAN_DETAILS_BREAKDOWN: "This is your loan so far...",
      PRODUCT_LABEL_LOAN_DETAILS_PRINCIPAL_AMOUNT: "Loan amount: ",
      PRODUCT_LABEL_LOAN_DETAILS_LOAN_TERM: "Loan term: ",
      PRODUCT_LABEL_LOAN_DETAILS_INTEREST_RATE: "* Interest rate: ",
      PRODUCT_LABEL_LOAN_DETAILS_MONTHLY_PAYMENT: "** Monthly payment: ",
      PRODUCT_LABEL: "Loan product",
      PRODUCT_LABEL_DEFAULT_OPTION: "Select your loan product",
      PURPOSE_LABEL: "Loan purpose",
      PURPOSE_LABEL_DEFAULT_OPTION: "Select the purpose of the loan",
      PROPERTY_VALUE_LABEL: "Property value",
      DOWNPAYMENT_AMOUNT_LABEL: "Down payment amount",
      DOWNPAYMENT_AMOUNT_PLACEHOLDER: "Amount",
      DOWNPAYMENT_AMOUNT_HINT: "To the nearest whole dollar",
      DOWNPAYMENT_PERCENTAGE_PLACEHOLDER: "Percent",
      DOWNPAYMENT_PERCENTAGE_HINT: "Or enter a percentage",
      INSURANCE_LABEL: "Default mandatory mortgage insurance amount",
      AMOUNT_LABEL: "Amount",
      AMOUNT_PLACEHOLDER: "Enter amount",
      AMOUNT_HINT:
        "This is usually the purchase price minus your down payment.",
      TERM_LABEL: "Loan term",
      TERM_DEFAULT_OPTION: "Select the loan term",
      MORTGAGE_ESTIMATE_LABEL: "* Estimated mortgage required",
      LOAN_QUESTION1: "Is this loan for another person or third-party?",
      LOAN_QUESTION2: "What will the loan be used for?",
      AMORTIZATION_LABEL: "Loan amortization",
      AMORTIZATION: "Amortization",
      AMORTIZATION_DEFAULT_OPTION: "Choose an amortization period",
      REPAYMENT_FREQUENCY_LABEL: "Payment frequency",
      INTEREST_RATE_LABEL: "Interest rate",
      INTEREST_RATE_OTPION: "Choose an available rate option",
      OWN_INTEREST_RATE_LABEL: "Enter your rate",
      OWN_INTEREST_RATE_HINT:
        "The interest rate must be between 0% and 30%. *Note to developers, means we will stick a variable defined by the FI in there.",
      FIRST_REPAYMENT_DATE_LABEL: "First payment date",
      MONTHLY_PAYMENT: "Monthly payment",
      MORTGAGE_TABLE_TITLE: "Here's how your mortgage is looking so far...",
      PERSONAL_LOAN_FINE_PRINT1:
        "* Final interest rates are determined at the time of loan approval.",
      PERSONAL_LOAN_FINE_PRINT2:
        "** You will be able to set the payment frequency based on your needs once the loan has been approved.",
      MORTGAGE_FINE_PRINT1:
        "* Final interest rates are determined at the time of loan approval.",
      MORTGAGE_FINE_PRINT2:
        "** You will be able to set the payment frequency based on your needs once the loan has been approved.",
      NO_ACCOUNTS: "No accounts available.",
    },
    FORMAT: {
      CURRENCY: "###,###.00",
      DECIMAL: "",
    },
    API_KEYS: {
      GET_LOAN_PRODUCT: "GET_LOAN_PRODUCT",
      GET_LENDING_PRODUCT: "GET_LENDING_PRODUCT",
      UPDATE_LENDING_PRODUCT: "UPDATE_LENDING_PRODUCT",
      LOAN_DELETE_FINANCIAL: "LOAN_DELETE_FINANCIAL",
      LOAN_DELETE_LOAN_SECURITY: "LOAN_DELETE_LOAN_SECURITY",
      LOAN_UPDATE_LENDING_PRODUCT: "LOAN_UPDATE_LENDING_PRODUCT",
      LOAN_UPDATE_LOAN_SECURITY: "LOAN_UPDATE_LOAN_SECURITY",
      LOAN_GET_LOAN_SECURITY: "LOAN_GET_LOAN_SECURITY",
      GET_FINANCIALANALYSYS_LIST: "GET_FINANCIALANALYSYS_LIST",
      GET_FINANCIALANALYSYS_FINANCIAL_LIST:
        "GET_FINANCIALANALYSYS_FINANCIAL_LIST",
    },
  },
  LOAN_PURPOSE: {
    TITLE: "Personal Loan - Premium Usecured",
    BODY_TITLE: "Getting Started",
    BREADCRUMB: "Getting Started",
    SUMMARY_TITLE: "Loan purpose",
    FORM: {
      PRODUCT_LABEL_PURPOSE: "Select the purpose of this loan.",
      AUTO_LOAN_PRODUCT_LABEL_PURPOSE: "How will you be buying your vehicle?",
      PRODUCT_LABEL_DEFAULT_OPTION: "Select your loan product",
      PURPOSE_LABEL: "Loan purpose",
      PURPOSE_LABEL_DEFAULT_OPTION: "Select the purpose of the loan",
      PURPOSE_LABEL_INPUT_OPTION: "What is the purpose of the loan?",
    },
    API_KEYS: {
      GET_LOAN_PRODUCT: "GET_LOAN_PRODUCT",
    },
  },
  PROPERTY_DETAILS: {
    TITLE: "Your Secured Property",
    PAGE_DESCRIPTION: "Tell us about the property.",
    BREADCRUMB: "Property",
    FORM: {
      UNIT_LABEL: "Apt. / Unit No.",
      IF_APPLICABLE: "(If applicable)",
      ADDRESS_SUBTITLE: "Where is your property located?",
      ADDRESS_DESCRIPTION1:
        "Please make sure the address you provide is for the secure property that you want to register for this Home Owner Equity Line of Credit.",
      STREETNUMBER_LABEL: "Street No.",
      STREETNAME_LABEL: "Street Name",
      STREETTYPE_LABEL: "Street Type",
      CITY_LABEL: "City",
      PROVINCE_LABEL: "Province / Territory",
      POST_CODE_LABEL: "Postal Code",
      PRIMARY_RESIDENCE: "Is this your primary residence? Select yes or no.",
      PROPERTY_LISTING_TYPE_LABEL: "Property Listing Type",
      VALUE_SUBTITLE: "PROPERTY VALUE",
      APPRAISED_VALUE_LABEL: "Appraised value",
      EXISTING_MORTGAGE_VALUE_LABEL: "Existing mortgage balance",
      PROPERTY_QUALIFYING_VALUE_LABEL: "Property qualifying value",
      ADD_BTN: "Add Another",
      VALUE_PLACEHOLDER: "Enter value",
      OWNERSHIP: "Property Ownership",
    },
    API_KEYS: {
      LOAN_ADD_REAL_ESTATE: "AddLoanRealEstate",
      LOAN_GET_REAL_ESTATE: "GetLoanRealEstate",
      LOAN_UPDATE_REAL_ESTATE: "UpdateLoanRealEstate",
    },
  },
  ASSET_PROPERTY: {
    TITLE: "Property Ownership",
    PAGE_DESCRIPTION: "Add any properties you may own.",
    BREADCRUMB: "Properties",
    FORM: {
      CONFIRM_PROPERTY: "Do you own any properties?",
      PROPERTY_NO: "PROPERTY NO.",
      REMOVE_PROPERTY: "Remove Property",
      UNIT_LABEL: "Apt. / Unit No.",
      IF_APPLICABLE: "(If applicable)",
      ADDRESS_SUBTITLE: "ADDRESS",
      STREETNUMBER_LABEL: "Street No.",
      STREETNAME_LABEL: "Street Name",
      STREETTYPE_LABEL: "Street Type",
      CITY_LABEL: "City",
      PROVINCE_LABEL: "Province / Territory",
      POST_CODE_LABEL: "Postal Code",
      PRIMARY_RESIDENCE: "Is this your primary residence? Select yes or no.",
      PROPERTY_LISTING_TYPE_LABEL: "Property Listing Type",
      VALUE_SUBTITLE: "PROPERTY VALUE",
      APPRAISED_VALUE_LABEL: "Appraised value",
      EXISTING_MORTGAGE_VALUE_LABEL: "Existing mortgage balance",
      PROPERTY_QUALIFYING_VALUE_LABEL: "Property qualifying value",
      ADD_BTN: "Add Another",
      VALUE_PLACEHOLDER: "Enter value",
    },
    ERROR: {
      STREET_NO_REQUIRED: "street number",
      STREET_NAME_REQUIRED: "street name",
      CITY_REQUIRED: "city",
      PROVINCE_REQUIRED: "province or territory",
      POSTAL_REQUIRED: "postal code",
      TENURE_REQUIRED: "property ownership",
    },
  },
  SECURITY_VEHICLE: {
    TITLE: "Vehicle Details",
    PAGE_DESCRIPTION:
      "Add any vehicles you may own, lease or are currently financing.",
    BREADCRUMB: "Vehicles",
    // SECURITY VEHICLE COMPONENT STRINGS
    FORM: {
      CONFIRM_VEHICLE: "Do you own or lease a vehicle?",
      VEHICLE_NO: "Vehicle No.",
      REMOVE_VEHICLE: "Remove Vehicle",
      MAKE_LABEL: "Make",
      MAKE_DESCRIPTION: "Enter vehicle make",
      MODEL_LABEL: "Model",
      MODEL_DESCRIPTION: "Enter vehicle model type",
      YEAR_LABEL: "Year",
      VIN_LABEL: "VIN",
      CURRENT_VALUE_LABEL: "Current estimated value",
      QUESTION1:
        "Are there any current outstanding loans against this vehicle?",
      VALUE_PLACEHOLDER: "Enter value",
      ADD_BTN: "Add Another",
    },
    API_KEYS: {
      LOAN_ADD_VEHICLE: "AddLoanVehicle",
      LOAN_GET_VEHICLE: "GetLoanVehicle",
      LOAN_UPDATE_VEHICLE: "UpdateLoanVehicle",
    },
    ERROR: {
      YEAR_REQUIRED: "vehicle year",
      MAKE_REQUIRED: "make",
      MODEL_REQUIRED: "model",
      VIN_REQUIRED: "vin",
    },
  },
  INCOME: {
    TITLE: "ADDITIONAL SOURCES OF INCOME",
    SUBTITLE: "Income",
    PAGE_DESCRIPTION:
      "Apart from your employment income, add any additional sources of income.",
    BREADCRUMB: "Income",
    FORM: {
      INCOME_RADIO_LABEL: "Do you have any additional sources of income?",
      INCOME_TYPE_NO: "INCOME NO. ",
      INCOME_TYPE: "Income type",
      INCOME_TYPE_DEFAULT_SELECT: "Select income type",
      ADJUSTMENT: "Adjustmet",
      ADJUSTMENT_TYPE_DEFAULT_SELECT: "Select adjustment type",
      DESCRIPTION: "Description",
      MONTHLY_AMOUNT: "Monthly amount",
      MONTHLY_AMOUNT_PLACEHOLDER: "Amount before taxes",
      REMOVE_INCOME: "Remove income",
      ADD_BTN: "Add Another",
    },
    API_KEYS: {
      LOAN_ADD_INCOME: "AddLoanIncome",
      LOAN_GET_INCOME: "GetLoanIncome",
      LOAN_UPDATE_INCOME: "UpdateLoanIncome",
    },
    ERROR: {
      INCOME_TYPE_REQUIRED: "income type.",
      DESCRIPTION_REQUIRED: "description.",
      MONTHLY_AMOUNT_REQUIRED: "monthly amount.",
    },
  },
  EXPENSE: {
    TITLE: "EXPENSES",
    SUBTITLE: "Expense",
    PAGE_DESCRIPTION1: "List your monthly household expenses here.",
    PAGE_DESCRIPTION2: "(Like your phone bill, internet, cable...)",
    BREADCRUMB: "Expenses",
    FORM: {
      EXPENSE_RADIO_LABEL: "Do you have any monthly expenses?",
      EXPENSE_TYPE_NO: "EXPENSE NO.",
      EXPENSE_TYPE: "Expense",
      EXPENSE_TYPE_DEFAULT_SELECT: "Select expense type",
      DESCRIPTION: "Description",
      MONTHLY_AMOUNT: "Monthly amount",
      MONTHLY_AMOUNT_PLACEHOLDER: "Enter amount",
      ADJUSTMENT: "Adjustment",
      PRIMARY_RESIDENCE:
        "Is this expense related to your primary residence? Select yes or no.",
      PRIMARY_RESIDENCE_TYPE_DEFAULT_SELECT: "Select yes or no",
      REMOVE_EXPENSE: "Remove expense",
      ADD_BTN: "Add Another",
      YES: "Yes",
      NO: "No",
    },
    API_KEYS: {
      LOAN_ADD_EXPENSE: "AddLoanExpense",
      LOAN_GET_EXPENSE: "GetLoanExpense",
      LOAN_UPDATE_EXPENSE: "UpdateLoanExpense",
    },
    ERROR: {
      EXPENSE_TYPE_REQUIRED: "expense type.",
      DESCRIPTION_REQUIRED: "description.",
      MONTHLY_AMOUNT_REQUIRED: "monthly amount.",
    },
  },
  ASSET: {
    TITLE: "ASSETS",
    SUBTITLE: "Asset",
    BREADCRUMB: "Assets",
    PAGE_DESCRIPTION1:
      "Now let's list any assets you have to assist you with your application approval.",
    PAGE_DESCRIPTION2: "(Add as many as you like.)",
    FORM: {
      ASSET_RADIO_LABEL: "Do you have any assets?",
      ASSET_TYPE_NO: "ASSET NO.",
      ASSET_TYPE: "Asset type",
      ASSET_TYPE_DEFAULT_SELECT: "Select an Asset type",
      DESCRIPTION: "Description",
      VALUE: "Estimated value",
      VALUE_AMOUNT_PLACEHOLDER: "Enter amount",
      ORGANIZATION: "Indicate the organization your asset is connected with",
      ORGANIZATION_DEFAULT_SELECT: "Select organization",
      ORGANIZATION_TITLE: "Institution",
      ADJUSTMENT: "Adjustment",
      ADJUSTMENT_TYPE_DEFAULT_SELECT: "Select adjustment type",
      PRIMARY_RESIDENCE:
        "Is this asset related to your primary residence? Select yes or no.",
      PRIMARY_RESIDENCE_TITLE: "Primary Residence",
      REMOVE_ASSET: "Remove asset",
      ADD_BTN: "Add Another",
    },
    API_KEYS: {
      LOAN_ADD_ASSET: "AddLoanAsset",
      LOAN_GET_ASSET: "GetLoanAsset",
      LOAN_UPDATE_ASSET: "UpdateLoanAsset",
    },
    ERROR: {
      ASSET_TYPE_REQUIRED: "asset type.",
      ORGANIZATION_REQUIRED: "organization.",
      VALUE_REQUIRED: "amount.",
      DESCRIPTION_REQUIRED: "description.",
    },
  },
  LIABILITIES: {
    TITLE: "LIABILITIES",
    SUBTITLE: "Liability",
    BREADCRUMB: "Liabilities",
    PAGE_DESCRIPTION1:
      "List any regular monthly payments you make relating to other loans, credit facilities or any other financial obligations.",
    PAGE_DESCRIPTION2:
      "(This does not include your regular monthly household expenses like your utility payments.)",
    FORM: {
      LIABILITY_RADIO_LABEL: "Do you have any liabilites?",
      LIABILITY_TYPE: "Liability type",
      LIABILITY_TYPE_NO: "LIABILITY NO.",
      LIABILITY_TYPE_DEFAULT_SELECT: "Select the liability type",
      DESCRIPTION: "Description",
      VALUE: "Outstanding balance",
      VALUE_AMOUNT_PLACEHOLDER: "Enter amount",
      ORGANIZATION: "Indicate the organization your asset is connected with",
      ORGANIZATION_TITLE: "Institution",
      ORGANIZATION_TYPE_DEFAULT_SELECT: "Select organization",
      ADJUSTMENT: "Adjustment",
      ADJUSTMENT_TYPE_DEFAULT_SELECT: "Select adjustment",
      SECURED_LABEL: "Is this a secured or unsecured loan?",
      SECURED_TYPE: "Secured Type",
      SECURED: "Secured",
      UNSECURED: "Unsecured",
      PRIMARY_RESIDENCE:
        "Is this liability related to your primary residence? Select yes or no.",
      PRIMARY_RESIDENCE_TITLE: "Primary Residence",
      PRIMARY_RESIDENCE_TYPE_DEFAULT_SELECT: "Select Answer",
      LIMIT: "Original loan amount / Max credit limit",
      RATE: "Rate (%)",
      RATE_PLACEHOLDER: "Enter rate",
      PAYMENT_TYPE: "Payment type",
      PAYMENT_TYPE_DEFAULT_SELECT: "Select payment type",
      REMOVE_LIABILITY: "Remove liability",
      ADD_BTN: "Add Another",
      AVG_PAYMENT: "Average monthly payment",
      YES: "Yes",
      NO: "No",
      AMORTIZATION_TERM_OF_MORTGAGE: "Amortization term of mortgage",
      NEW_LOAN_USED_TO_PAY_OFF_LIABILITY_LABEL:
        "Will this new loan be used to pay off this liability?",
      ENTER_AMOUNT_NEW_LOAN_APPLIED_TO_THIS_LIABILITY_LABEL:
        "Enter the amount of the new loan that will be applied to this liability",
      MORTGAGE: {
        VALUE: "Outstanding balance on mortgage",
        LIMIT: "Orignal Mortgage amount",
        DESCRIPTION: "Describe this mortgage",
        AMORTIZATION_TERM_OF_MORTGAGE: "Amortization term of mortgage",
      },
      VEHICLE_LOAN: {
        DESCRIPTION: "Make & Model",
        VALUE: "Value (balance remaining)",
        LIMIT: "Original limit",
        TERM_OF_LOAN: "Term of loan",
      },
      CREDIT_CARD: {
        DESCRIPTION: "Type (Visa, Mastercard, Amex...)",
        VALUE: "Balance",
        LIMIT: "Credit limit",
      },
      LINE_OF_CREDIT: {
        DESCRIPTION: "Type",
        VALUE: "Balance",
        LIMIT: "Credit limit",
      },
      LOAN: {
        DESCRIPTION: "Description",
        VALUE: "Balance",
        LIMIT: "Original limit",
      },
    },
    API_KEYS: {
      LOAN_ADD_LIABILITIES: "AddLoanLiabilities",
      LOAN_GET_LIABILITIES: "GetLoanLiabilities",
      LOAN_UPDATE_LIABILITIES: "UpdateLoanLiabilities",
    },
    ERROR: {
      SUB_TYPE_REQUIRED: "sub type.",
      ORGANIZATION_REQUIRED: "institution.",
      VALUE_REQUIRED: "value.",
      DESCRIPTION_REQUIRED: "description.",
      SECURED_TYPE_REQUIRED: "secured type",
      ADJUSTMENT_REQUIRED: "adjustment type.",
      LIMIT_REQUIRED: "limit.",
      AVG_PAYMENT_REQUIRED: "average payment.",
      RATE_REQUIRED: "rate.",
      PAYMENT_TYPE_REQUIRED: "payment type.",
      LOAN_FOR_DEBT_AMOUNT_REQUIRED: "amount of loan to apply.",
    },
  },
  DOCUMENT_UPLOAD: {
    TITLE: "DOCUMENT UPLOAD",
    SUBTITLE:
      "In order for us to process your application, we require you to upload the following documents:",
    BREADCRUMB: "Documents",
    UPLOAD_MESSAGE:
      "In order for us to process your application, we require you to upload the following documents:",
    UPLOAD_MESSAGE1:
      "Upload any documents that you may feel will help to support your application.",
    UPLOAD_MESSAGE2:
      "Upload any documents that help support any statements or claims made within this application.",
    FILES_FOR_UPLOAD: "Files Ready For Upload",
    FILES_FOR_UPLOAD_FINE_PRINT1: " ",
    FILES_FOR_UPLOAD_FINE_PRINT2: " ",
    FILES_FOR_UPLOAD_FINE_PRINT3: " ",
    TAP_FOR_CAMERA: "Tap to take a picture or browse files to upload",
    CLICK_TO_BROWSE:
      "Drag & drop files here or click the link to browse files for upload.",
    CLICK_TO_BROWSE_LINK: "Browse files for upload",
    UPLOAD_BUTTON: "Continue",
    SKIP_BUTTON: "Skip for now",
    UPLOAD_CANCEL: "Cancel",
    API_KEYS: {
      ADD_DOCUMENT: "AddDocument",
      GET_DOCUMENT_LIST: "GetDocumentList",
      DELETE_DOCUMENT: "DeleteDocument",
    },
  },
  FEES: {
    TITLE: "Fees",
    BREADCRUMB: "Fees",
    PSA_REGISTRATION_LABEL: "PSA registration fee",
    PSA_SEARCH_FEES_LABEL: "PSA search fees",
    PROPERTY_SEARCH_LABEL: "Property search fees",
    TITLE_INSURANCE_LEGAL_FEES_LABEL: "Title insurance legal fees",
    APPRAISAL_INSPECTION_LABEL: "Appraisal inspection fees",
    APPLICATION_DISBURSEMENT_LABEL: "Application disbursement fees",
    TOTAL_FEES_LABEL: "Total Fees",
  },
  BEFORE_WE_START: {
    TITLE: "Before We Start",
    BREADCRUMB: "Before We Start",
  },
  COST_OF_BORROWING: {
    TITLE: "Cost of Borrowing",
    TOTAL_COST_OF_CREDIT_TITLE: "Total Cost of Credit",
    BREADCRUMB: "Cost of Borrowing",
    FEES_LABEL: "Fees",
    TOTAL_AMOUNT_LABEL: "Total Amount",
    AMOUNT_REQUESTED_LABEL: "Amount Requested",
    TOTAL_TO_BE_FINANCED_LABEL: "Total to be Financed",
    TOTAL_PRINCIPAL_AMOUNT_LABEL: "Total Loan Amount",
    TOTAL_NO_OF_PAYMENTS_LABEL: "Total No. of Payments",
    FINAL_PAYMENT_AMOUNT_LABEL: "Final Payment Amount",
    APR_LABEL: "APR",
    EQUIVALENCY_RATE_LABEL: "Equivalency Rate",
    EQUIVALENCY_RATE_APR_LABEL: "Equivalency Rate APR",
    NEGATIVE_AM_RATE_LABEL: "Negative AM Rate",
    TOTAL_INTEREST_PAID_LABEL: "Total Interest Paid",
    TOTAL_COST_OF_BORROWING_LABEL: "Total Cost of Borrowing",
  },
  LOAN_SUMMARY: {
    TITLE: "Loan Summary",
    BREADCRUMB: "Loan Summary",
    SUBTITLE_SUCCESS1: "Congratulations!",
    SUBTITLE_SUCCESS2: "You are approved.",
    SUBTITLE_SUCCESS3: "Your finalized loan details are listed below.",
    SUBTITLE_FAIL: "Thank you for submitting your application.",
    BREAKDOWN: "Loan Breakdown",
    COST_TO_BORROW: "Cost to Borrow",
    PAYMENT: "payment",
    PAYMENTS: "Payments",
    MONTHLY_PAYMENT: "Monthly payment: ",
    INTEREST_RATE: "Interest Rate",
    INT_PAID_OT: "Interest paid over term",
    INT_PAID_OT_VALUE: "$1,800.00",
    LOAN_FEES: "Loan fees",
    LOAN_FEE_VALUE: "$5.00",
    TOTAL_COST_BORROW: "Total cost to borrow",
    TOTAL_COST_BORROW_VALUE: "$1,850.00",
    VIEW_ALL: "View all",
    FORM: {
      DESCRIPTION_REPAYMENT:
        "Select your desired payment frequency and first payment date to view your updated loan details.",
      PRODUCT_LABEL_REPAYMENT_SCHEDULE_DATE:
        "Select a date for your first payment",
      SCHEDULE_LABEL: "Payment frequency",
      SCHEDULE_OPTION_LABEL: "Select your payment frequency",
      FIRST_DATE_LABEL: "First payment date",
      REFRESH_BTN_LABEL: "Refresh Details",
      REFRESH_BTN_SPACER: "  ",
    },
    LOC: {
      LABEL_SUMMARY: "Your line of credit summary is outlined below.",
      LOAN_TERM: "Revolving",
      REPAYMENT_FREQUENCY: "Member's discretion",
    },
  },
  LOAN_ADJUDICATION_RESULTS: {
    TITLE: "Loan Result",
    BREADCRUMB: "Loan Result",
  },
  DISBURSEMENT: {
    TITLE: "Disbursement",
    SUBTITLE: "Indicate which account you would like us to deposit your funds.",
    LOCAL_ACCOUNTS_SUBTITLE: `Your ${FIInformation.FI_NAME} Accounts`,
    OTHER_ACCOUNTS: "Other Account",
    OTHER_FINANCIAL_ACCOUNTS: "Other Financial Institution",
    BREADCRUMB: "Disbursement",
    DEPOSIT_INTERNAL: "Deposit Internal",
    DEPOSIT_EXTERNAL: "Deposit External",
    CONSOLIDATION_INTERNAL: "Consolidation Internal",
    CONSOLIDATION_EXTERNAL: "Consolidation External",
    AUTO: "Auto",
    FORM: {
      ACCOUNT_NO: "Account No.",
      ACCOUNT_DESC: "Account Description",
      INSTITUTION_NO: "Institution No.",
      TRANSIT_NO: "Transit No.",
      AMOUNT: "Amount",
      COMMENTS: "Comments",
      COMMENTS_DESCRIPTION: "Add any comments, notes or instructions here",
      PAYEE: "Payee",
      ACCOUNT_TYPE: "Account type",
      ACCOUNT: "Account",
      ACCOUNT_TYPE_DEFAULT_SELECT: "Select the account type",
      ACCOUNT_DEFAULT_SELECT: "Select the account",
      CHANGE_FIN: "Change financial institution",
    },
    API_KEYS: {
      GET_LENDING_PRODUCT: "GetLendingProduct",
      UPDATE_LENDING_PRODUCT: "UpdateLendingProduct",
    },
    ERROR: {
      REQUIRED: "Please select disbursement info",
      REQUIRED_OTHER: "Please select disbursement account",
    },
  },
  REPAYMENT: {
    TITLE: "Repayment",
    SUBTITLE:
      "Now indicate which account we should withdraw your payments from.",
    LOCAL_ACCOUNTS_SUBTITLE: `Your ${FIInformation.FI_NAME} Accounts`,
    OTHER_ACCOUNTS: "Other Account",
    OTHER_FINANCIAL_ACCOUNTS: "Other Financial Institution",
    BREADCRUMB: "Repayment",
    FORM: {
      ACCOUNT_NO: "Account No.",
      ACCOUNT_DESC: "Account description",
      ACCOUNT_TYPE: "Account type",
      ACCOUNT_TYPE_DEFAULT_SELECT: "Select the account type",
      INSTITUTION_NO: "Institution No.",
      TRANSIT_NO: "Transit No.",
      AMOUNT: "Amount",
      COMMENTS: "Comments",
      COMMENTS_DESCRIPTION: "Add any comments, notes or instructions here.",
      PAYEE: "Payee",
      ACCOUNT: "Account",
      ACCOUNT_DEFAULT_SELECT: "Select the account",
      CHANGE_FIN: "Change financial institution",
    },
    ERROR: {
      REQUIRED: "Please select repayment info",
      REQUIRED_OTHER: "Please select repayment account",
    },
  },

  // Begin Business
  BUSINESS_EXIST: {
    TITLE: "EXISTING BUSINESS MEMBER",
    BREADCRUMB: "Existing Member",
    FORM: {
      CHOICE_MESSAGE:
        "Is your business already a Mobetize member with access to online banking?",
    },
  },
  BUSINESS_CLIENT: {
    TITLE: "EXISTING PERSONAL MEMBER",
    BREADCRUMB: "Personal Member",
    FORM: {
      CHOICE_MESSAGE:
        "Are you already a Mobetize member with access to your personal online banking?",
    },
  },
  BUSINESS_KYC: {
    TITLE: "ABOUT YOU",
    BREADCRUMB: "Business Client",
    FORM: {},
  },
  BUSINESS_TYPE: {
    TITLE: "Business Type",
    BREADCRUMB: "Before We Start",
    FORM: {
      SUBTITLE: "Type Of Business",
      BUSINESS_TYPE_CATEGORY_LABEL: `What type of business are you?`,
      YES_TO_OTHER_BUSINESS_TYPE_TITLE: "We're Sorry...",
      YES_TO_OTHER_BUSINESS_TYPE_BODY: `Your business needs appear to be unique.`,
      YES_TO_OTHER_BUSINESS_TYPE_BODY1: `For all other business types, please reach out to one of our representatives or come see us at one of our branches.`,
      REMOVE_FROM_VAULT_AND_GO_BACK: `Remove application and return to ${FIInformation.FI_APPLICATION_NAME}`,
    },
    ERROR: {
      TYPE_REQUIRED: "type of business.",
    },
  },
  BUSINESS_ELIGIBILITY: {
    TITLE: "Before We Start",
    BREADCRUMB: "Before We Start",
    FORM: {
      SUBTITLE: "Online Application Eligibility",
      IS_CANNABIS: "Is your business in the cannabis industry?",
      IS_FINANCIAL_INSTITUTION: "Is your business a financial institution?",
      YES_TO_CANNABIS_TITLE: "We're Sorry...",
      YES_TO_CANNABIS_BODY: `Your business needs appear to be unique and therefore do not qualify for the _PRODUCT_.`,
      YES_TO_CANNABIS_BODY1: `Please reach out to one of our friendly customer service <a href="${FIInformation.FI_CONTACT_US}" target="_blank">business representatives</a> or come see us at one of our branches.`,
      YES_TO_FININCIAL_INSTITUTION_TITLE: "We're Sorry...",
      YES_TO_FININCIAL_INSTITUTION_BODY: `Your business needs appear to be unique and therefore do not qualify for the _PRODUCT_.`,
      YES_TO_FININCIAL_INSTITUTION_BODY1: `Please reach out to one of our friendly customer service <a href="${FIInformation.FI_CONTACT_US}" target="_blank">business representatives</a> or come see us at one of our branches.`,
      REMOVE_FROM_VAULT_AND_GO_BACK: `Remove application and return to ${FIInformation.FI_APPLICATION_NAME}`,
    },
    ERROR: {
      IS_CANNABIS_OR_MONEY_REQUIRED:
        "if your business in the cannabis industry.",
      IS_FINANCIAL_INSTITUTION_REQUIRED:
        "if your business a financial institution.",
    },
  },
  // BUSINESS MEMBER CREATION COMPONENT STRINGS
  BUSINESS_INFO: {
    TITLE: "For this application you will need...",
    BREADCRUMB: "Let's Begin",
    FIRST_CARD_TEXT:
      "Be sure you and any co-applicants have reached the age of majority.",
    FIRST_CARD_MOREINFO: `The age of majority is 18 in six provinces: Alberta, Manitoba, Prince Edward Island, Quebec, Saskatchewan, and Ontario.<br></br> The age of majority is 19 in four provinces and the three territories: British Columbia, New Brunswick, Newfoundland, Northwest Territories, Nova Scotia, Nunavut, and Yukon.`,
    SECOND_CARD_TEXT:
      "You and any co-applicants have a valid Social Insurance Number (SIN).",
    SECOND_CARD_MOREINFO: `A valid SIN is necessary for any and all primary and/or joint account holders for the purpsoses of tax reporting.`,
    THIRD_CARD_TEXT:
      "You and any owners or signers have a valid Govt issued ID.",
    THIRD_CARD_MOREINFO: `A valid Govt. issued ID is required by law and is necessary for assisting in the identity validation process of any new or prospective members.`,
    FOURTH_CARD_TEXT: `New business members are required to purchase ${FIInformation.FI_BUSINESS_SHARE_AMOUNT} Membership Equity Shares.`,
    FOURTH_CARD_MOREINFO: `When you become a ${FIInformation.FI_NAME} member, you become part owner of ${FIInformation.FI_NAME} by purchasing ${FIInformation.FI_BUSINESS_SHARE_AMOUNT} in member shares.\<br></br>\ Not only do you earn interest on these shares each year, but they give you the opportunity to vote on major decisions and help steer how the credit union is run.`,
    ADDITIONAL_DOCUMENTS_TITLE:
      "We may also ask you to upload these documents...",
    ADDITIONAL_DOCUMENTS_BODY:
      "We will ask you to upload any additional supporting documents at the end of this application.",
    TERM_TEXT:
      "By continuing, you confirm that you have read and agree to the terms and conditions.",
    F1_TEXT: `Keep me informed about ${FIInformation.FI_NAME} news updates and special offers.`,
    F1_ALERT: `You also understand that some financial applications may require ${FIInformation.FI_NAME} to request a hard credit check. This may or may not impact your credit score. If a hard credit check will be required for an application, you will be alerted to it and asked to "Agree" before being asked to submit your application.`,
    TERM_TEXT_DETAIL: `You also understand that some financial applications may require ${FIInformation.FI_NAME} to request a hard credit check. This may or may not impact your credit score. If a hard credit check will be required for an application, you will be alerted to it and asked to "Agree" before being asked to submit your application.`,
    TERMS_ERROR_TEXT: "agree to the above statements, terms and/or conditions",
    CONTACT_DETAILS: "",
    GOVT_ID: "GOVT ID",
    SIN_TEXT: "SIN",
    MEMBERSHARE_TEXT: "Member Shares",
    AGREE_TERMS: "Please read and agree to the following:",
    AGREE_TERMS_ERROR: "You must accept and agree to continue.",
    AGE_TEXT: "18+",
    FORM: {
      SUBTITLE: "About Your Business",
      PHONE_NO: "Business phone no.",
      EMAIL: "Business email",
      BRANCH: "Select a preferred home branch location",
      CORRESPONDENCE:
        "How would you like to receive your account statements and correspondence?",
      OVERDRAFT_PROTECTION: "Will you need overdraft protection",
    },
    API_KEYS: {
      TERMS_JSON: "termsJson",
      TERMS_LIST: "termsList",
    },
  },
  BUSINESS_START: {
    TITLE: "Getting Started",
    BREADCRUMB: "Getting Started",
    FORM: {
      DECISION_TO_OPEN_AN_ACCOUNT:
        "What was the reason that led to the decision to open an account with Mobetize?",
      RELATIONSHIP_BUSINESS:
        "What is the intended relationship between your business and Mobetize?",
      BRANCH:
        "Select a branch you feel your business will do most of its banking with.",
      RECEIVE_ACCOUNT_STATEMENTS:
        "How would you like to receive your account statements?",
    },
    PAPER: "Paper",
    EMAIL: "Email",
    ERROR: {
      DECISION_TO_OPEN_AN_ACCOUNT_REQUIRED:
        " reason that led to the decision to open an account with Mobetize.",
      RELATIONSHIP_BUSINESS_REQUIRED:
        "the intended relationship between your business and Mobetize.",
      BRANCH_REQUIRED:
        "a branch you feel your business will do most of its banking with.",
      RECEIVE_ACCOUNT_STATEMENTS_REQUIRED: "account statement.",
    },
  },
  BUSINESS_INFO_ADDRESS: {
    TITLE: "Business Info - Address",
    BREADCRUMB: "Business Info - Address",
    // FOR_REVIEW_HEADER: "For Review",
    FOR_REVIEW_DESCRIPTION: `Below is the registered legal corporate address we found on file.`,
    FOR_REVIEW_DESCRIPTION1: `Please update any incorrect or missing address fields as necessary.`,
    BUSINESS_ADDRESS: "BUSINESS ADDRESS",
    FORM: {
      STREET_NUMBER_LABEL: "Street number",
      STREET_NAME_LABEL: "Street name",
      SUITE_NO_LABEL: "Suite No.",
      CITY_LABEL: "City",
      PROVINCE_LABEL: "Province/Territory",
      POSTAL_CODE_LABEL: "Postal Code",
      NO_RECORDS_FOUND: "Sorry, no addresses found",
      DONT_SEE_ADDRESS: "I don't see my address",
      SEARCH_MESSAGE: "Start typing to search for your address.",
      PHYSICAL_ADDRESS_REQ:
        "P.O. Boxes are not accepted as physically registered addresses.",
    },
    ERROR: {
      STREET_NUMBER_REQUIRED: "street number",
      STREET_NAME_REQUIRED: "street name",
      SUITE_NO_REQUIRED: "suite no",
      CITY_REQUIRED: "city.",
      PROVINCE_REQUIRED: "province.",
      POSTAL_CODE_REQUIRED: "postal code.",
    },
  },
  BUSINESS_INFO_MAIL: {
    TITLE: "Business Info Mail",
    BREADCRUMB: "Business Info Mail",
    // FOR_REVIEW_HEADER: "For Review",
    FOR_REVIEW_DESCRIPTION: `Below is the registered legal corporate MAILING address we found on file.`,
    FOR_REVIEW_DESCRIPTION1: `Please update any incorrect or missing MAILING address fields as necessary.`,
    BUSINESS_MAILING_ADDRESS: "BUSINESS MAILING ADDRESS",
    SAME_AS_BUSINESS_ADDRESS:
      "Is your mailing address the same as your business address?",
    FORM: {
      STREET_NAME_LABEL: "Street name",
      STREET_NUMBER_LABEL: "Street number",
      SUITE_NO_LABEL: "PO Box / Unit / Suite No.",
      SUITE_NO_PLACEHOLDER: "If applicable",
      CITY_LABEL: "City",
      PROVINCE_LABEL: "Province/Territory",
      POSTAL_CODE_LABEL: "Postal Code",
      SEARCH_MESSAGE:
        "Start typing in your address to auto-fill this information.",
      PHYSICAL_ADDRESS_REQ: " ",
    },
    ERROR: {
      STREET_NUMBER_REQUIRED: "street number",
      STREET_NAME_REQUIRED: "street name",
      SUITE_NO_REQUIRED: "suite no",
      CITY_REQUIRED: "city.",
      PROVINCE_REQUIRED: "province.",
      POSTAL_CODE_REQUIRED: "postal code.",
    },
  },
  BUSINESS_LOOKUP: {
    TITLE: "Business Look-up",
    BREADCRUMB: "Business Look-up",
    BUSINESS_REGISTRY: "Business Registry Look-up",
    BUSINESS_REGISTRY_DESCRIPTION:
      "In order to continue your application, we need to find your business in the business registry.",
    BUSINESS_REGISTRY_DESCRIPTION1:
      "We can find your business either with your legal business name or your business number.",
    BUSINESS_NUMBER: "Business Number",
    LEGAL_BUSINESS_NAME: "Legal Business Name",
    FORM: {
      BUSINESS_NAME_NUMBER_LABEL: "How should we search for your business?",
      LEGAL_BUSINESS_NAME_TITLE: "LEGAL BUSINESS NAME",
      LEGAL_BUSINESS_NAME_LABEL: "Enter your legal business name",
      LEGAL_BUSINESS_NAME_PLACEHOLDER: "Enter Business Name",
      BUSINESS_NUMBER_TITLE: "BUSINESS NUMBER",
      BUSINESS_NUMBER_LABEL: "Enter your business number (BN)",
      BUSINESS_NUMBER_PLACEHOLDER: "Enter Business Number ",
      BUSINESS_NUMBER_DESCRIPTION:
        "Your business number will be 8 digits long.",
      FOUND_MANY_BUSINESS_HEADER:
        "It appears we may have found more than 1 business that may match your search.",
      FOUND_BUSINESS_SUBHEADER:
        "Please select your business from the list, or try looking up your business using the business number option.",
      SELECT_BUSINESS_JURISDICTION:
        "Select the province or territory your business is registered in.",
      SELECT_BUSINESS: "Select your business from the dropdown.",
      SELECT_PROVINCE:
        "Select the province or territory your business is registered in.",
      FOUND_ONE_BUSINESS_HEADER: "We think we found you!",
      FOUND_ONE_BUSINESS_DESCRIPTION: `If this is you, hit "Continue".`,
      FOUND_ONE_BUSINESS_DESCRIPTION1: `If not, check your spelling or try using your business number to look up your business.`,
      SELECT_BUSINESS_ID: "SELECT_BUSINESS",
      SELECT_PROVINCE_ID: "SELECT_PROVINCE",
    },
    PAPER: "Paper",
    EMAIL: "Email",
    ERROR: {
      BUSINESS_LOOKUP_REQUIRED: "business lookup.",
      LEGAL_BUSINESS_NAME_REQUIRED: "legal business name.",
      BUSINESS_NUMBER_REQUIRED: "business number",
      BUSINESS_JURISDICTION: "business jurisdiction",
      BUSINESS_NUMBER_NOT_FOUND:
        "We couldn't find you. Please check the business number you entered.",
      BUSINESS_NAME_NOT_FOUND:
        "We couldn't find you. Please check the business name you entered.",
      BUSINESS_NOT_FOUND_TITLE: "Hmmm...",
      BUSINESS_NOT_FOUND_DESCRIPTION:
        "Sorry, we couldn't find your business. Please try again later or contact your Financial Institution.",
    },
  },
  BUSINESS_CONTACT: {
    TITLE: "Business Contact",
    BREADCRUMB: "Business Contact",
    // CORRESPONDENCE_TITLE: "For Future Correspondence",
    CORRESPONDENCE_DESCRIPTION:
      "Please provide a business number and an email address that we can save on file.",
    CORRESPONDENCE_DESCRIPTION1:
      "In the future, our friendly staff we will use this contact information to correspond with your business.",
    CONTACT_FORM_TITLE: "BUSINESS CONTACT INFO",
    FORM: {
      BUSINESS_PHONE_NO: "Business phone no.",
      BUSINESS_PHONE_NO_PLACEHOLDER: "Enter business phone",
      EXTENSION: "Extension",
      EXTENSION_PLACEHOLDER: "If applicable",
      EMAIL: "Business email",
      EMAIL_PLACEHOLDER: "Enter Business email",
    },
  },
  BUSINESS_DETAILS: {
    TITLE: "Business Details",
    BREADCRUMB: "Business Details",
    BUSINESS_TRADE_NAME: "Business Trade name",
    FORM: {
      BUSINESS_NUMBER: "Business no.",
      BUSINESS_NUMBER_PLACEHOLDER: "Business number",
      INCORPORATION_NUMBER: "Incorporation no.",
      INCORPORATION_NUMBER_PLACEHOLDER: "Incorporation number",
      JURISDICTION_INCORPORATION: "Jurisdiction of Incorporation",
      JURISDICTION_INCORPORATION_PLACEHOLDER: "Jurisdiction of incorporation",
      BUSINESS_REGISTRATION_DATE: "Business registration date",
      BUSINESS_REGISTRATION_DATE_PLACEHOLDER: "YYYY/MM/DD",
      LEGAL_BUSINESS_NAME: "Legal business name",
      LEGAL_BUSINESS_NAME_PLACEHOLDER: "Enter legal business name",
      BUSINESS_OPERATE_TRADE_NAME_LABEL:
        "Does your business operate under a trade name that differs from your legal business name?",
      TRADE_NAME_LABEL: "Trade name",
      TRADE_NAME_LABEL_EXTENSION: "(if it differs from the legal name)",
      TRADE_NAME_LABEL_PLACEHOLDER: "If applicable",
      TRADE_NAME_DESCRIPTION1:
        "If your business operates with a trade name, you may need documentation to support that.",
      TRADE_NAME_DESCRIPTION2:
        "We will give you an opportunity to upload your Trade Name Document at the end of this application.",
      CERTIFICATE_INCORPORATION_LABEL:
        "Do you have a Certificate of Incorporation?",
      CERTIFICATE_INCORPORATION: "Certificate of Incorporation",
      CERTIFICATE_INCORPORATION_DESCRIPTION:
        "You will have an opportunity to upload your Certificate of Incorporation document at the end of this application.",
      NO_CERTIFICATE_INCORPORATION_DESCRIPTION: `In order to fulfill this application, ${FIInformation.FI_NAME} will require a copy of your Certificate of Incorporation. You will have an opportunity to upload your Certificate of Incorporation document at the end of this application or you may bring it into a branch. `,
    },
    HELP: {
      MORE_INFO_TRADE_NAME_TITLE: `Trade names Vs. Legal names`,
      MORE_INFO_TRADE_NAME_DESCRIPTION: `Your trade name, or sometimes referred to as your "operating name", is the name you use in your day-to-day activities and to advertise your business. It is the name your customers or clients know you as. For example, Walmart's official business name, Wal-Mart Inc., differs from its trade name, which is simply Walmart. While Walmart is commonly used in advertising and on its website, legally, it operates as Wal-Mart Inc. for government paperwork, including filings, taxes, and compliance.\<br></br>\ You only need to provide your trade name if it is different from your legal name.`,
    },
    YES: "Yes",
    NO: "No",
  },
  BUSINESS_OPERATIONS: {
    TITLE: "Business Operations",
    BREADCRUMB: "Business Operations",
    FORM: {
      WAS_BUSINESS_LEGALLY_FORMED_LABEL:
        "Was your business legally formed outside of Canada?",
      COUNTRY_INCORPORATION: "Country of Incorporation",
      COUNTRY_INCORPORATION_LABEL:
        "Select the country your business was first formed in.",

      DOES_BUSINESS_PHYSICAL_LOCATION_LABEL:
        "Does your business have a physical location outside of Canada?",
      PHYSICAL_LOCATION: "Physical locations outside of Canada",
      PHYSICAL_LOCATION_LABEL:
        "Select or add countries your business has physical locations in.",

      DOES_BUSINESS_TRANSACT_LABEL:
        "Does your business transact outside of Canada?",
      COUNTRY_TRANSACTIONS: "Countries of transactions",
      COUNTRY_TRANSACTIONS_LABEL:
        "Select or add countries your business transacts with.",

      COUNTRY: "Country #",
      COUNTRY_LABEL: "Select the country.",

      BUSINESS_ACTIVE_LABEL:
        "Would you say your business is an active or passive entity?",
      BUSINESS_SECTOR_LABEL: "What sector is your business in?",
      BUSINESS_SUB_SECTOR_LABEL: "What sub-sector is your business in?",
      BUSINESS_GROUP_LABEL: "What industry group is your business in?",
      BUSINESS_INDUSTRY_LABEL: "What industry is your business in?",
      BUSINESS_DESCRIBE_INDUSTRY_LABEL:
        "Select from the options that best describe your business industry.",
      NAICS_CODE: "NAICS Code",
      NAICS_CODE_LABEL: "Do you know your business' NAICS code?",
      NAICS_CODE_FORM_LABEL: "Enter your business' NAICS code.",
      NAICS_CODE_FORM_PLACEHOLDER: "Eg. 123456",
      NAICS_CODE_HINT_TEXT:
        "Your business's NAICS code will be a maximum of 6 digits.",
      NAICS_CODE_DESCRIPTION:
        "For us to ascertain an applicable NAICS code for your business, answer the series of questions below by selecting the best appropriate option from the dropdown selectors.",
      NAICS_CODE_FOUND_FORM_LABEL: "NAICS code",
      NAICS_CODE_FOUND_HINT_TEXT:
        "This is your business's NAICS code based on the selections you made above.",
      ACTIVE_BTN: "Active",
      PASSIVE_BTN: "Passive",
      ADD_BTN: "Add Another",
      REMOVE_BTN: "Remove Country",
    },
    HELP: {
      ACTIVE_VS_PASSIVE: `If more than 50% of your business' income is earned by producing or providing a good or service, then your business is considered to be an "Active" entity.\<br></br>\ If 50% or more of your business' income is earned from investments or assets held, then your business is considered to be a "Passive" entity.`,
      NAICS_CODE: `A NAICS code is simply a series of six digits that identify the primary income-earning activity of a business.\<br></br>\ In Canada, NAICS codes are primarily used by statistical agencies to gather data and information, but it plays a role come tax time.`,
    },
    ERROR: {
      SECTOR_REQUIRED: "business sector.",
      SUB_SECTOR_REQUIRED: "business sub-sector.",
      GROUP_REQUIRED: "business group.",
      INDUSTRY_REQUIRED: "business industry.",
      INDUSTRY_DESCRIBE_REQUIRED: "best describes your business industry.",
      COUNTRY_REQUIRED: "country.",
      ENTITY_REQUIRED: "entity.",
    },
  },
  BUSINESS_TAX: {
    TITLE: "Business Taxation",
    BREADCRUMB: "Business Taxes",
    FORM: {
      IS_BUSINESS_CONSIDERED_NOT_FOR_PROFIT:
        "Is your business considered a not-for-profit business?",
      NOT_FOR_PROFIT: "NOT-FOR-PROFIT",
      NOT_FOR_PROFIT_LABEL:
        "Select the best answer to describe your not for profit situation.",
      REGISTERED_CRA: "Yes - and the business is registered with the CRA",
      REGISTERED_CRA_ID: "Yes and is registered",
      NOT_REGISTERED_CRA:
        "Yes - but the business is not registered with the CRA",
      NOT_REGISTERED_CRA_ID: "Yes and not registered",
      DOES_BUSINESS_SOLICIT_DONATIONS: "Does your business solicit donations?",

      DOES_BUSINESS_FILE_TAXES_IN_USA:
        "Apart from filing taxes in Canada, does your business also file taxes in the USA?",
      TAX_IDENTITY_NUMBER_USA: "Tax Identification Number (TIN) - USA",
      TAX_IDENTITY_NUMBER_USA_LABEL: "Enter your U.S. TIN.",
      TAX_IDENTITY_NUMBER_USA_PLACEHOLDER: "123-45-6789",
      TAX_IDENTITY_NUMBER_USA_DESCRIPTION: "Your TIN will be 9 digits long.",

      DOES_BUSINESS_FILE_TAXES_IN_OTHERS:
        "Does your business file taxes in any other countries?",
      TAX_IDENTITY_NUMBER: "Tax Identification Number (TIN) ",
      COUNTRY_FILE_LABEL:
        "Select or add countries your business files taxes in.",

      COUNTRY: "Country #",
      COUNTRY_LABEL: "Select the country.",
      TIN_LABEL: "Enter your TIN.",
      TIN_PLACEHOLDER: "123456789",

      ADD_BTN: "Add Another",
      REMOVE_BTN: "Remove",
    },
    ERROR: {
      NOT_FOR_PROFIT_REQUIRED: "best describe your not for profit situation",
      TIN_REQUIRED: "TIN",
      TIN_USA_REQUIRED: "U.S. TIN",
      COUNTRY_REQUIRED: "country",
    },
  },
  BUSINESS_COMPLIANCE: {
    TITLE: "Business Compliance",
    BREADCRUMB: "Business Compliance",
    FORM: {
      INTENDED_PURPOSE_LABEL: "What is the intended purpose of this account?",
    },
    ERROR: {
      INTENDED_PURPOSE_REQUIRED: "intended purpose of this account",
    },
  },
  BUSINESS_OVERDRAFT: {
    TITLE: "Business Overdraft",
    BREADCRUMB: "Business Overdraft",
    FORM: {
      ACCOUNT_OVERDRAFT_LABEL: "Will this account require overdraft?",
      // OVERDRAFT_PROTECTION_HINT_TEXT: `You can help minimize the risk of automatic payments bouncing with a ${FIInformation.FI_OVERDRAFT_DEFAULT_AMOUNT} overdraft limit on your chequing account.`,
      // OVERDRAFT_PROTECTION_HINT_TEXT1: `For the current overdraft rate, go to <a href="${FIInformation.FI_OVERDRAFT_URL}" target="_blank">${FIInformation.FI_OVERDRAFT_URL}</a>.`,
      // AGREE_TERMS: "Please read and agree to the following:",
    },
    ERROR: {
      OVERDRAFT_REQUIRED: "if account will require overdraft",
    },
  },
  BUSINESS_CHEQUE: {
    TITLE: "Business Cheque",
    BREADCRUMB: "Business Cheque",
    FORM: {
      ACCOUNT_PRINTED_CHEQUES_LABEL:
        "Will this account require printed cheques?",
    },
    ERROR: {
      CHEQUE_REQUIRED: "if account will require printed cheques",
    },
  },
  BUSINESS_DEBIT: {
    TITLE: "Business Debit",
    BREADCRUMB: "Business Debit",
    FORM: {
      ACCOUNT_DEBIT_CARDS_LABEL: "Will this account require debit cards?",
    },
    ERROR: {
      ACCOUNT_DEBIT_CARDS_REQUIRED: "if account will require debit cards",
    },
  },
  BUSINESS_ONLINE: {
    TITLE: "Business Online",
    BREADCRUMB: "Business Online",
    FORM: {
      ACCOUNT_ONLINE_BANKING_LABEL:
        "Will this account require online banking setup?",
    },
    ERROR: {
      ACCOUNT_ONLINE_BANKING_REQUIRED: "if account will online banking setup",
    },
  },
  BUSINESS_EMPLOYMENT: {
    TITLE: "Business Employment",
    BREADCRUMB: "Business Employment",
    FORM: {
      EMPLOYMENT_STATUS_LABEL: "Employment status",
      POSITION_LABEL: "Position",
      POSITION_PLACEHOLDER: "Enter your position",
      EMPLOYER_NAME_LABEL: "Employer name",
      EMPLOYER_NAME_PLACEHOLDER: "Enter your company or employer's name",
    },
    ERROR: {
      EMPLOYMENT_STATUS_REQUIRED: " employment status",
      POSITION_REQUIRED: " position",
      EMPLOYER_NAME_REQUIRED: " your company or employer's name",
    },
  },
  BUSINESS_REVIEW: {
    TITLE: "Busienss Review",
    BREADCRUMB: "Business Review",
    REVIEW_DETAILS_MSG1:
      "Let's just take a moment to make sure we have the details all correct.",
    REVIEW_DETAILS_MSG2: "Please review your details before continuing.",
    BUSINESS_TYPE: "BUSINESS TYPE",
    TYPE_OF_BUSINESS: "Type of Business",
    BUSINESS_ELIGIBILITY: "ONLINE APPLICATION ELIGIBILITY",
    IS_CANNABIS_OR_MONEY:
      "Is your business in the cannabis or money services industry?",
    IS_FINANCIAL_INSTITUTION: "Is your business a financial institution?",
    BUSINESS_START: "GETTING STARTED",
    DECISION_TO_OPEN_AN_ACCOUNT:
      "What was the reason that led to the decision to open an account with Mobetize?",
    RELATIONSHIP_BUSINESS:
      "What is the intended relationship between your business and Mobetize?",
    BRANCH:
      "Select a branch you feel your business will do most of its banking with.",
    RECEIVE_ACCOUNT_STATEMENTS:
      "How would you like to receive your account statements?",
    BUSINESS_REGISTRATION: "BUSINESS REGISTRATION",
    LEGAL_BUSINESS_NAME_LABEL: "Legal Business name:",
    TRADE_NAME_LABEL: "Trade name: ",
    BUSINESS_NUMBER_LABEL: "Business number:",
    BUSINESS_REGISTERED_ADDRESS: "BUSINESS - REGISTERED ADDRESS",
    ADDRESS: "Address Line 1",
    ADDRESS2: "Address Line 2",
    CITY: "City",
    PROVINCE: "Province/Territory:",
    POSTAL_CODE: "Postal code:",
    MAILING_ADDRESS_AS_LISTED: "Mailing address same as listed: ",
    BUSINESS_REGISTERED_MAILING_ADDRESS:
      "BUSINESS - REGISTERED MAILING ADDRESS",
    BUSINESS_CONTACT_DETAILS: "BUSINESS CONTACT DETAILS",
    PHONE_NUMBER: "Phone number:",
    BUSINESS_EMAIL: "Business email address",
    BUSINESS_DETAILS: "BUSINESS DETAILS",
    OPERATE_UNDER_TRADE_NAME: "Operate under a trade name:",
    CERTIFICATE_OF_INCORPORATION: "Certificate of incorporation",
    BUSINESS_OPERATIONS: "BUSINESS OPERATIONS",
    LEGALLY_FORMED_OUTSIDE_CANADA: "Legally formed outside of Canada:",
    LOCATION_OUTSIDE_CANADA: "Locations outside of Canada:",
    TRANSACT_OUTSIDE_CANADA: "Transact outside of Canada",
    REVENUE_IS_PASSIVE: "Revenue is 'passive' or 'active':",
    NAICS_CODE: "NAICS code:",
    INDUSTRY_SECTOR: "Industry sector:",
    INDUSTRY_SUBSECTOR: "Industry sub-sector:",
    INDUSTRY_GROUP: "Industry group:",
    INDUSTRY: "Industry:",
    INDUSTRY_DESCRIPTION: "Industry description:",
    BUSINESS_TAXATION: "BUSINESS TAXATION",
    NOT_FOR_PROFIT: "Not-for-profit:",
    FILE_TAXES_IN_USA: "File taxes in USA:",
    FILE_TAXES_IN_OTHER: "File taxes in other countries:",
    COUNTRY_NO: "Country #",
    INTENDED_PURPOSE_ACCOUNT: "INTENDED PURPOSE OF ACCOUNT",
    INTENDED_PURPOSE_ACCOUNT_LABEL: "Intended purpose of the account: ",
    OVERDRAFT: "OVERDRAFT",
    OVERDRAFT_LABEL: "Will this account require overdraft: ",
    PRINTED_CHEQUES: "PRINTED CHEQUES",
    PRINTED_CHEQUES_LABEL: "Will this account require printed cheques: ",
    DEBIT_CARD: "DEBIT CARDS",
    DEBIT_CARD_LABEL: "Will this account require debit cards: ",
    ONLINE_BANKING: "ONLINE BANKING",
    ONLINE_BANKING_LABEL: "Will this account require online banking: ",
    EDIT_BTN_TEXT: "Edit",
    SUCCESS_POP_UP_TITLE: "Great work.",
    SUCCESS_POP_UP_DESCRIPTION: "Next step — things about your new account.",
  },
  BUSINESS_INDIVIDUAL: {
    TITLE: "Business Individual Review",
    BREADCRUMB: "Business Individual Review",
    REVIEW_DETAILS_MSG1: "Thank you for completing your application.",
    REVIEW_DETAILS_MSG2:
      "Please review your details before confirming and submitting your request.",
    BUSINESS_ABOUT_YOU: "ABOUT YOU",
    TYPE_OF_BUSINESS: "Type of Business",
    BUSINESS_EMPLOYMENT: "EMPLOYMENT",
    IS_CANNABIS_OR_MONEY:
      "Is your business in the cannabis or money services industry?",
    IS_FINANCIAL_INSTITUTION: "Is your business a financial institution?",
    BUSINESS_START: "GETTING STARTED",
    DECISION_TO_OPEN_AN_ACCOUNT:
      "What was the reason that led to the decision to open an account with Mobetize?",
    RELATIONSHIP_BUSINESS:
      "What is the intended relationship between your business and Mobetize?",
    BRANCH:
      "Select a branch you feel your business will do most of its banking with.",
    RECEIVE_ACCOUNT_STATEMENTS:
      "How would you like to receive your account statements?",
    BUSINESS_REGISTRATION: "BUSINESS REGISTRATION",
    LEGAL_BUSINESS_NAME_LABEL: "Legal Business name:",
    TRADE_NAME_LABEL: "Trade name: ",
    BUSINESS_NUMBER_LABEL: "Business number:",
    BUSINESS_REGISTERED_ADDRESS: "BUSINESS - REGISTERED ADDRESS",
    ADDRESS: "Address Line 1",
    ADDRESS2: "Address Line 2",
    CITY: "City",
    PROVINCE: "Province/Territory:",
    POSTAL_CODE: "Postal code:",
    MAILING_ADDRESS_AS_LISTED: "Mailing address same as listed: ",
    BUSINESS_REGISTERED_MAILING_ADDRESS:
      "BUSINESS - REGISTERED MAILING ADDRESS",
    BUSINESS_CONTACT_DETAILS: "BUSINESS CONTACT DETAILS",
    PHONE_NUMBER: "Phone number:",
    BUSINESS_EMAIL: "Business email address",
    BUSINESS_DETAILS: "BUSINESS DETAILS",
    OPERATE_UNDER_TRADE_NAME: "Operate under a trade name:",
    CERTIFICATE_OF_INCORPORATION: "Certificate of incorporation",
    BUSINESS_OPERATIONS: "BUSINESS OPERATIONS",
    LEGALLY_FORMED_OUTSIDE_CANADA: "Legally formed outside of Canada:",
    LOCATION_OUTSIDE_CANADA: "Locations outside of Canada:",
    TRANSACT_OUTSIDE_CANADA: "Transact outside of Canada",
    REVENUE_IS_PASSIVE: "Revenue is 'passive' or 'active':",
    NAICS_CODE: "NAICS code:",
    INDUSTRY_SECTOR: "Industry sector:",
    INDUSTRY_SUBSECTOR: "Industry sub-sector:",
    INDUSTRY_GROUP: "Industry group:",
    INDUSTRY: "Industry:",
    INDUSTRY_DESCRIPTION: "Industry description:",
    BUSINESS_TAXATION: "BUSINESS TAXATION",
    NOT_FOR_PROFIT: "Not-for-profit:",
    FILE_TAXES_IN_USA: "File taxes in USA:",
    FILE_TAXES_IN_OTHER: "File taxes in other countries:",
    COUNTRY_NO: "Country #",
    INTENDED_PURPOSE_ACCOUNT: "INTENDED PURPOSE OF ACCOUNT",
    INTENDED_PURPOSE_ACCOUNT_LABEL: "Intended purpose of the account: ",
    OVERDRAFT: "OVERDRAFT",
    OVERDRAFT_LABEL: "Will this account require overdraft: ",
    PRINTED_CHEQUES: "PRINTED CHEQUES",
    PRINTED_CHEQUES_LABEL: "Will this account require printed cheques: ",
    DEBIT_CARD: "DEBIT CARDS",
    DEBIT_CARD_LABEL: "Will this account require debit cards: ",
    ONLINE_BANKING: "ONLINE BANKING",
    ONLINE_BANKING_LABEL: "Will this account require online banking: ",
    EDIT_BTN_TEXT: "Edit",
    SUCCESS_POP_UP_TITLE: "Great work.",
    SUCCESS_POP_UP_DESCRIPTION: "Next step — document upload.",
  },
  BUSINESS_MEMBER: {
    TITLE: "Business Member",
    BREADCRUMB: "Business Member",
    SUB_HEADER:
      "CORPORATE OWNERS, OFFICERS & DIRECTORS, AUTHORIZED SIGNATORIES",
    WARNING_MSG: "We could not find any registered signatories on file.",
    FOUND_MSG1: "We found",
    FOUND_MSG2: "registered signatories on file.",
    INSTRUCTION_DETAILS:
      "Please add any missing details and indicate each of these individuals' roels.",
    OWNER: "Owner",
    OFFICE_DIRECTOR: "Office or Director",
    AUTHORIZED_SIGNATORY: "Authorized Signatory",
    FORM: {
      ADDITIONAL_AUTHORIZED_SIGNATORIES: "ADDITIONAL AUTHORIZED SIGNATORIES",
      ADDITIONAL_AUTHORIZED_QUESTION:
        "Does your business have any other owners, officers/directors, or authorized signatories not already listed?",
      GROUP_LABEL:
        "Listed Owner, Director, Officer, and/or Authorized signatory",
      FIRSTNAME_LABEL: "First name",
      LASTNAME_LABEL: "Last name",
      EMAIL_LABEL: "Email address",
      MOBILE_NO_LABEL: "Mobile no.",
      OWNER_LABEL: "Owner",
      OFFICER_DIRECTOR_LABEL: "Office or Director",
      AUTHORIZED_SIGNATORY_LABEL: "Authorized Signatory",
      MEMBER_ROLES: "Business member role(s)",
      ADD_BTN: "Add Another",
      REMOVE_BTN: "Remove",
    },
  },
  BUSINESS_MEMBER_REVIEW: {
    TITLE: "Business Member Review",
    BREADCRUMB: "Business Member Review",
    SUB_HEADER:
      "CORPORATE OWNERS, OFFICERS & DIRECTORS, AUTHORIZED SIGNATORIES",
  },
  BUSINESS_ABOUT_YOU: {
    TITLE: "Business About You",
    BREADCRUMB: "Business About You",
    SUB_HEADER: "YOUR RELATIONSHIP TO THE BUSINESS",
    SUB_HEADER_ROLES:
      "CORPORATE OWNERS, OFFICERS & DIRECTORS, AUTHORIZED SIGNATORIES",
    DESCRIPTION1:
      "In the previous step, you listed individuals that were considered authorized signatories.",
    DESCRIPTION2:
      "(Shareholders of 25% or more, officers & directors, and other individual authorized signatories)",
    INDICATE_WHO: "Indicate you are from the list below.",
    NONE: "None of the above",
  },
  BUSINESS_CONFIRM: {
    TITLE: "Business Confirmation",
    BREADCRUMB: "Confirmation",
    MAIN_MSG: "Thank you for completing your application.",
    REVIEW_DETAILS_MSG:
      "Please review your details before confirming and submitting your request.",
    FORM: {
      SUBTITLE: "About Your Business",
      BUSINESS_TYPE: "Type of Business",
      CANNABIS: "Business is engaged in cannabis or money services industry?",
      FINANCIAL_INSTITUTION: "Is your business a financial institution?",
      PHONE: "Phone number",
      EMAIL: "Email address",
      BRANCH: "Preferred home branch location",
      CORRESPONDENCE:
        "How to receive your account statements and correspondence?",
      OVERDRAFT: "Overdraft protection",
    },
    SUCCESS_POP_UP_TITLE: "Excellent!",
    SUCCESS_POP_UP_DESCRIPTION:
      "You are well on your way to finishing up your business's financial product application. Next step — business members.",
  },
  BUSINESS_UPLOAD: {
    TITLE: "Business Documents",
    BREADCRUMB: "Document Upload",
    INFO:
      "In order for us to facilitate your application, we require you to upload the following documents:",
    FOLLOWING_DOCUMENTS: [
      "Certificate of Incorporation",
      "Articles of incorporation",
      "Board resolution",
    ],
    ATTACHED_DOCUMENTS: "Attached Documents",
  },
  BUSINESS_DOC_REVIEW: {
    TITLE: "Business Documents Review",
    BREADCRUMB: "Document Review",
    INFO:
      "Let's just take a moment to make sure we have the details all correct.",
    REVIEW_INFO: "Please review your details before continuing.",
    DOCUMENTS: "DOCUMENTS",
    DOCUMENTS_REQUIRED_INFO: "Documents required to support this application:",
    FOLLOWING_DOCUMENTS: [
      "Certificate of Incorporation",
      "Articles of incorporation",
      "Board resolution",
    ],
    ATTACHED_DOCUMENTS: "Documents attached for upload",
    DOCUMENT_NOTE:
      "NOTE: You can upload documents from your home dashboard at a later time, or come into the branch",
  },
  TAX_INFO: {
    TITLE: "TAX INFO",
    BREADCRUMB: "Tax Info",
    FORM: {
      TAX_RESIDENT_CONFIRMATION: "Are you a tax resident of Canada? ",
      COUNTRY: "COUNTRY",
      COUNTRYFIELDNAME: "countryName",
      MONTHLY_GROSS_INCOME_FIELDNAME: "monthlyGrossIncome",
      RESIDENT_OF_OTHER_COUNTRY_CONFIRMATION:
        "Are you a tax resident of any other country? ",
      RESIDENT_OF_OTHER_CONFIRMATION:
        "Are you a tax resident of any other country except Canada or USA? ",
      RESIDENT_OF_USA: "Are you a tax resident of USA? ",
      RECIDENT_OF_COUNTRY_IN_SELECTION_CONFIRMATION: "Are you a citizen of ",
      ADD_ANOTHER_COUNTRY: "Add Another Country ",
      TAX_IDENTITY_NO: "Tax Identification Number (TIN)",
      SSN: "Social Security Number (SSN)",
      TAX_NUMBER: "Tax Number",
      SIN: "Social Insurance Number (SIN)",
      TAX_RESIDENCY: "CANADIAN TAX RESIDENT",
      USA_TAX_RESIDENCY: "USA TAX RESIDENT",
      FOREIGN_TAX_RESIDENCY: "FOREIGN TAX RESIDENCY",
      SELECT_COUNTRY: "Select a foreign country you are a tax resident of.",
      SELECT_BEST_OPTION_THAT_DESCRIBES_YOU:
        "Select the option that best describes you",
      LIST_THE_COUNTRIES: "List the countries you are a tax resident of",
      PLACEHOLDER: {
        COUNTRY: "Select country",
        ADD_ANOTHER_COUNTRY: "Add Another Country ",
        SSN: "Enter tax number",
        SIN: "Enter social insurance number (SIN)",
        LIST_THE_COUNTRIES: "List the countries you are a tax resident of",
        SINPLAACEHOLDER: "Social insurance number (SIN)",
      },
      ERROR: {
        TAX_RESIDENT_CONFIRMATION: "whether you are tax resident of canada",
        COUNTRY: "country name",
        TAX_RESIDENT_OF_OTHER_COUNTRY_CONFIRMATION:
          "whether you are tax resident of another country or not",
        RECIDENT_OF_COUNTRY_IN_SELECTION_CONFIRMATION:
          "whether you are a resident of another country or not",
        TAX_IDENTITY_NO: "tax identity number",
        SSN: "tax number",
        SSN_USA: "tax number",
        SIN: "social insurance number (SIN)",
        LIST_OF_COUNTRIES: "countries you are a tax resident of",
        BOTHSELECTEDNONEERROR:
          "you must be the tax resident of at least one country",
        COUNTRY_LIST: "country list",
        INCOME_LIST: "incomesource list",
        EMPLOYMENT_STATUS: "employment status",
        JOB_TITLE: "job title",
      },
    },
  },
  GIC_INFO: {
    TITLE: "GIC Details",
    SUBTITLE: "Enter your GIC details.",
    BREADCRUMB: "GIC Info",
    FORM: {
      AMOUNT: "GIC amount",
      TERM: "GIC term (months)",
      INTEREST: "Interest rate for this GIC",
      INTENDED_USE: "What is the intended use of the account?",
      PLACEHOLDER: {},
      GIC_MIN_MSG: "Amount must be equal or greater than",
      GIC_MAX_MSG: "Amount must be equal or less than",
      ERROR: {
        AMOUNT: "amount",
        INTENDED_USE: "intended use",
      },
    },
  },
  APPLY_PRODUCT: {
    APPLY_PRODUCT_BREADCRUMB: "apply",
    SUPPORT_MESSAGE: "Invalid product id encountered.",
    API_KEYS: {
      SUPPORT_MESSAGE: "supportMessage",
    },
    INVALID_PRODUCT:
      "Product selected is invalid. Please select on the list of products",
  },
  // MODAL WINDOW TITLES
  WELCOME_TITLE1: "Welcome...",
  WELCOME_BACK_TITLE: "Great to see you again!",
  WELCOME_BACK1: `Welcome back... we are just fetching the information we have on file for you.`,
  WELCOME_BACK2: `Before we continue with your application, please review or update your information.`,
  ADDRESS_SEARCH: {
    NO_RECORDS_FOUND: "Sorry, no addresses found",
    DONT_SEE_ADDRESS: "I don't see my address",
    SEARCH_MESSAGE: "Start typing to search for your address.",
    RESIDENTIAL_ADDRESS_REQ:
      "P.O. Boxes are not acceptable residential addresses.",
  },
  QUESTIONNAIRE: {
    TITLE: "Help Me Choose",
    THANKYOU_TEXT1:
      "Thank you! Here are some options we think will suit your needs the best.",
    THANKYOU_TEXT2:
      "If you feel these options are not suitable, and would like to continue to explore other suitable options, you can review and edit your answers below.",
    SORRY_TEXT1: "We're sorry.",
    SORRY_TEXT2: "We couldn't find a recommended product based on your answers",
    SORRY_HINT_TEXT1: `You can try modifying your answers below or try reaching out of us by <a href="${FIInformation.FI_EMAIL}" target="_blank">contacting us here</a>.`,
    RECOMMENDATION_SUMMARY_TITLE: "Help Me Choose — Questions and answers for",
    RECOMMENDATION_TITLE: "Recommended products",
    EDIT_BTN_TEXT: "Edit",
    FORM: {
      SELECT_PLACEHOLDER: "Please select",
    },
    ERROR: {
      TEXT_REQUIRED: "text.",
      SELECT_REQUIRED: "a choice.",
    },
  },
  MEMBERSHIP: {
    BREADCRUMB: "Membership",
    INFO1:
      "This product requires a membership, which can be an existing one or a new one.",
    INFO2:
      "Your choice of membership assignment has no impact on your everyday banking.",
    FORM: {
      SELECT_MEMBERSHIP:
        "Choose from your existing memberships or assign a new one to this product.",
      SELECT_DEFAULT: "-- Select a membership or choose to create a new one --",
      CREATE_NEW: "+ Create New",
    },
    ERROR: {
      SELECT_MEMBERSHIP:
        "You must choose an existing membership or create a new membership in order to continue",
    },
  },
};

export default STRINGS;
