import AddressApiRequest from "./AddressApiRequest";
import { METHOD } from "../Constants";
import { GET_ADDRESS_URL } from "../ApiUrls";
import { formURL } from "Utils/CommonUtilities";

export default class AddressCorrection extends AddressApiRequest {
  method = METHOD.HTTP.GET;

  url = GET_ADDRESS_URL;
  headers = {};
  body = {};

  constructor(params) {
    super();
    this.headers["Authorization"] =
      "Basic RG94SW5jOTkxMzo5RDVsN0guOXZINF11QHlJViVASDRpK2o=";
    const paramList = [];
    this.url =
      this.url +
      "?Data.InstanceKey=" +
      params["InstanceKey"] +
      "&Data.AddressLine1=" +
      params["AddressLine1"] +
      "&Data.Country=" +
      params["Country"];
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };

  getHeader = () => {
    return this.headers;
  };
}
